import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EventItem } from './EventItem/EventItem'
import { Preloader } from '../../../../components/Preloader/Preloader'

import {
	getAfterEvents,
	getBeforeEvents,
	getFilters,
	getIsLoaded,
	getIsLoading,
	getNowEvents,
} from '../../../../store/reducers/events/events-selectors'

import { setFilters } from '../../../../store/reducers/events/events'

import { url } from '../../../../api/api'

import './Events.scss'

import { createDate } from '../../../../functions/common'

export const Events = React.memo(({ type, setDate, month }) => {
	const dispatch = useDispatch()

	const afterEvents = useSelector(getAfterEvents)
	const nowEvents = useSelector(getNowEvents)
	const beforeEvents = useSelector(getBeforeEvents)
	const { time, date_type: dateType } = useSelector(getFilters)
	const isLoading = useSelector(getIsLoading)
	const isLoaded = useSelector(getIsLoaded)

	const handleDateReset = () => {
		setDate(undefined)
		dispatch(
			setFilters({
				time: new Date(month.getFullYear(), month.getMonth(), 15),
				date_type: 'month',
			})
		)
	}

	const dateOptions = {
		month: 'long',
		day: 'numeric',
	}

	if (isLoading && isLoaded) return <Preloader full />

	return (
		<div className="events-container">
			{time && dateType === 'day' && (
				<div className="events__title">
					<span>{`События ${createDate(time, true, 'ru', dateOptions)}`}</span>
					<i onClick={handleDateReset}></i>
				</div>
			)}

			{(type === 0 || type === 2) &&
				nowEvents.map((event) => (
					<EventItem
						key={event.id}
						id={event.id}
						type={event.type}
						city={event.city}
						image={`${url}/static/${event.image}`}
						title={event.name}
						dateStart={createDate(event.time_start, 'ru', dateOptions)}
						dateEnd={createDate(event.time_end, 'ru', dateOptions)}
						moderating={event.status_props === 1}
						approved={event.status_props === 2}
						rejected={event.status_props === 3}
						count={event.count}
						planned={event.is_planned}
					/>
				))}

			{(type === 0 || type === 3) &&
				afterEvents.map((event) => (
					<EventItem
						key={event.id}
						id={event.id}
						image={`${url}/static/${event.image}`}
						title={event.name}
						dateStart={createDate(event.time_start, 'ru', dateOptions)}
						dateEnd={createDate(event.time_end, 'ru', dateOptions)}
						moderating={event.status_props === 1}
						approved={event.status_props === 2}
						rejected={event.status_props === 3}
						count={event.count}
						type={event.type}
						city={event.city}
						planned={event.is_planned}
					/>
				))}
			{(type === 0 || type === 1) && !!beforeEvents.length && (
				<div className="past-events">
					<div className="past-events__title">Прошедшие</div>
					<div className="past-events__container">
						{beforeEvents.map((event) => (
							<EventItem
								key={event.id}
								id={event.id}
								image={`${url}/static/${event.image}`}
								title={event.name}
								dateStart={createDate(event.time_start, 'ru', dateOptions)}
								dateEnd={createDate(event.time_end, 'ru', dateOptions)}
								moderating={event.status_props === 1}
								approved={event.status_props === 2}
								rejected={event.status_props === 3}
								count={event.count}
								type={event.type}
								city={event.city}
								planned={event.is_planned}
								past
							/>
						))}
					</div>
				</div>
			)}

			{((!afterEvents.length && !nowEvents.length && !beforeEvents.length) ||
				(!beforeEvents.length && type === 1) ||
				(!nowEvents.length && type === 2) ||
				(!afterEvents.length && type === 3)) && (
				<div className="events__plug">На выбранную дату не запланировано ни одного события</div>
			)}
		</div>
	)
})

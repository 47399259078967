import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import cn from 'classnames'

import { NavContext } from '../../Header.jsx'

import './NavItem.scss'

export const NavItem = React.memo(({ anchor, label }) => {
	const [active, setActive] = useState(false)

	const handleShowMenu = useContext(NavContext)

	const handleModileNavClick = () => {
		if (Boolean(handleShowMenu)) {
			handleShowMenu(false)
		}
	}

	const location = useLocation()
	const hash = location.hash

	useEffect(() => {
		if (hash.replace(/[0-9]/g, '') === anchor) setActive(true)
		else setActive(false)
	}, [hash, anchor])

	return (
		<div className="nav-link-wrapper" onClick={handleModileNavClick}>
			<a href={`/${anchor}`} className={cn('nav-link', { active })}>
				{label}
			</a>
			<div className="nav-link__active-marker"></div>
		</div>
	)
})

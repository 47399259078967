import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Thumbs, Navigation, A11y } from 'swiper'
import { url } from '../../api/api'

import { Popup } from './Popup/Popup'

import './Slider.scss'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/components/thumbs/thumbs.scss'

SwiperCore.use([Thumbs, Navigation, A11y])

export const Slider = React.memo(({ onClose, gallery, initial = 0 }) => {
	return (
		<div className="slider__wrapper">
			<Popup onClose={onClose}>
				<Swiper
					initialSlide={initial}
					className="main__slider"
					slidesPerView={1}
					navigation
					pagination={{
						el: '.swiper-pagination',
						type: 'bullets',
						clickable: true,
					}}
				>
					{gallery.map((item, index) => (
						<SwiperSlide key={`gallery-${index}`}>
							<img src={`${url}/static/${item}`} alt="Галерея" />
						</SwiperSlide>
					))}
				</Swiper>
				<div className="swiper-pagination"></div>
			</Popup>
		</div>
	)
})

import React from 'react'
import { Link } from 'react-router-dom'

import './EditQuestionnaireLink.scss'

export const EditQuestionnaireLink = React.memo(() => (
	<Link to="/form">
		<div className="edit-questionnaire-link">
			<i></i>
			<span> Редактировать анкету </span>
		</div>
	</Link>
))

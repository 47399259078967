import { createSlice } from '@reduxjs/toolkit'
import teamFinderApi from '../../../api/team-finder-api'
import { addNot } from '../global-nots/global-nots'
import { getInfo } from '../partners/partners'

const initialState = {
	users: [],
	usersCount: 0,
	isUserBought: false,
	isMsgLoading: false,
	usersFilters: {
		limit: 9,
		offset: 0,
		name: '',
		sort: '',
		skills: [],
		specializations: [],
	},
	local: {
		cities: {},
		countries: {},
		language: {},
		specs: [],
		techs: [],
	},
	isLoading: false,
	profile: {
		surname: '',
		name: '',
		patronymic: '',
		birthday: '',
		email: '',
		expWork: '',
		expIT: '',
		country: '',
		city: '',
		language: [],
		social: [],
		resumeLink: '',
		gitLink: '',
		about: '',
	},
	work: {
		workState: 0,
		organization: '',
		post: '',
		workOpen: false,
		motivation: '',
		workType: 0,
		country: [],
		city: [],
		monthCost: '',
		hourCost: '',
	},
	userSpecList: {},
	userTechList: {
		skills: {},
		other: [],
	},
}

const teamFinder = createSlice({
	name: 'teamFinder',
	initialState,
	reducers: {
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setIsMsgLoading: (state, action) => {
			state.isMsgLoading = action.payload.isMsgLoading
		},

		setUsersFilters: (state, action) => {
			state.usersFilters = { ...state.usersFilters, ...action.payload }
		},

		setUsers: (state, action) => {
			state.users = action.payload.users
		},

		setUsersCount: (state, action) => {
			state.usersCount = action.payload.usersCount
		},

		setIsUserBought: (state, action) => {
			state.isUserBought = action.payload.isUserBought
		},

		setLocal: (state, action) => {
			state.local = action.payload.local
		},

		resetLocal: (state) => {
			state.local = {
				cities: {},
				countries: {},
				language: {},
				specs: [],
				techs: [],
			}
		},

		setTel: (state, action) => {
			state.tel = action.payload.tel
		},

		resetTel: (state) => {
			state.tel = ''
		},

		setProfile: (state, action) => {
			state.profile = { ...state.profile, ...action.payload }
		},

		resetProfile: (state) => {
			state.profile = {
				surname: '',
				name: '',
				patronymic: '',
				birthday: '',
				email: '',
				expWork: '',
				expIT: '',
				country: '',
				city: '',
				language: [],
				social: [],
				resumeLink: '',
				gitLink: '',
				about: '',
			}
		},

		setWork: (state, action) => {
			state.work = { ...state.work, ...action.payload }
		},

		resetWork: (state) => {
			state.work = {
				workState: '',
				organization: '',
				post: '',
				workOpen: false,
				motivation: '',
				workType: '',
				country: [],
				city: [],
				monthCost: '',
				hourCost: '',
			}
		},

		setUserTechs: (state, action) => {
			state.userTechList.skills = action.payload.tech
		},

		setUserTechsOther: (state, action) => {
			state.userTechList.other = action.payload.other
		},

		setUserSpecs: (state, action) => {
			state.userSpecList = action.payload.specs
		},
	},
})

const { reducer, actions } = teamFinder

export const {
	setIsLoading,
	setLocal,
	setUsers,
	setUsersCount,
	setIsMsgLoading,
	setUsersFilters,
	setTel,
	setProfile,
	setWork,
	setUserSpecs,
	setUserTechs,
	setUserTechsOther,
	resetLocal,
	resetTel,
	resetProfile,
	setIsUserBought,
	resetWork,
} = actions

/*
Получение списка пользователей
*/
export const requestUsers = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		teamFinder: { usersFilters },
	} = getState()

	const res = await teamFinderApi.getUsers(usersFilters)

	if (res.success) {
		dispatch(setUsers({ users: res.result.users }))
		dispatch(setUsersCount({ usersCount: res.result.count }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

/*
Получение пользователя по id
*/
export const requestUserById = (id) => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await teamFinderApi.getUserById(id)

	if (res.success) {
		const {
			user: { tel, first_page, second_page, third_page, fourth_page, is_user_bought },
			local,
		} = res.result

		dispatch(setUserSpecs({ specs: third_page.specializations }))
		dispatch(setUserTechs({ tech: fourth_page.skills }))
		dispatch(setUserTechsOther({ other: Object.entries(fourth_page.other) }))
		dispatch(setLocal({ local }))
		dispatch(setTel({ tel }))

		/*
		подбиваю под то, что есть в профиле
		*/
		dispatch(
			setProfile({
				surname: first_page.surname,
				name: first_page.name,
				patronymic: first_page.patron,
				birthday: first_page.birthday ? new Date(first_page.birthday) : '',
				email: first_page.email,
				expWork: first_page.exp_work,
				expIT: first_page.exp_it,
				country: first_page.country,
				city: first_page.city,
				language: first_page.langs || [],
				social: first_page.social || [],
				resumeLink: first_page.resume_link,
				gitLink: first_page.git_link,
				about: first_page.about,
			})
		)

		dispatch(
			setWork({
				workState: second_page.work_state,
				organization: second_page.comp,
				post: second_page.post,
				workOpen: second_page.work_open,
				motivation: second_page.motivation,
				workType: second_page.work_type,
				country: second_page.countries || [],
				city: second_page.cities || [],
				monthCost: second_page.month_cost,
				hourCost: second_page.hour_cost,
			})
		)

		dispatch(setIsUserBought({ isUserBought: is_user_bought }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

/*
Покупка контактных данных пользователя
*/
export const buyUserInfo = (id) => async (dispatch) => {
	dispatch(setIsMsgLoading({ isMsgLoading: true }))

	const res = await teamFinderApi.buyUserInfo(id)

	if (res.success) {
		dispatch(addNot({ not: 'Данные успешно куплены', type: 'success' }))
		dispatch(getInfo())
		dispatch(requestUsers())
		dispatch(requestUserById(id))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsMsgLoading({ isMsgLoading: false }))
}

export default reducer

import React from 'react'
import cn from 'classnames'

import './ProgrammTab.scss'

export const ProgrammTab = React.memo(({ text, onClick = () => {}, active }) => (
	<div className={cn('programm-tab', { active })} onClick={onClick}>
		{text}
	</div>
))

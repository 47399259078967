import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CommentBlock } from './CommentBlock/CommentBlock'
import { CommentTextareaBlock } from './CommentTextareaBlock/CommentTextareaBlock'

import { requestComments, setComments, setFilters } from '../../store/reducers/comments/comments'

import { getComments, getCount, getFilters } from '../../store/reducers/comments/comments-selector'

import './Comments.scss'

export const Comments = React.memo(({ approved, certStatus }) => {
	const dispatch = useDispatch()

	const comments = useSelector(getComments)
	const commentsCount = useSelector(getCount)
	const { limit, offset } = useSelector(getFilters)

	const commentsRef = useRef()

	useEffect(() => {
		dispatch(requestComments())
	}, [dispatch, offset, limit])

	useEffect(
		() => () => {
			dispatch(setComments({ comments: [] }))
			dispatch(setFilters({ limit: 5, offset: 0 }))
		},
		[dispatch]
	)

	return (
		<div className="comments-block">
			<div className="comments-block__title">Комментарии ({commentsCount})</div>
			{approved && certStatus === 2 ? (
				<CommentTextareaBlock ref={commentsRef} />
			) : (
				<p className="bold attention-text">Комментарии могут оставлять только участники события.</p>
			)}

			<div className="comments-block__comments" ref={commentsRef}>
				{comments.map((comment) => (
					<CommentBlock commentInfo={comment} key={comment.time_create} />
				))}
			</div>
			{!!limit && commentsCount > 5 && (
				<div className="show-all" onClick={() => dispatch(setFilters({ offset: 0, limit: 0 }))}>
					Показать все комментарии
				</div>
			)}
		</div>
	)
})

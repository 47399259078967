import React from 'react'

import './ProgrammTabContentItem.scss'

export const ProgrammTabContentItem = React.memo(({ time, title, desc, speaker }) => (
	<div className="programm-tab-content-item">
		<div className="programm-tab-content-item__time">{time}</div>
		<div className="programm-tab-content-item__title">{title}</div>
		<div className="programm-tab-content-item__desc">
			<pre>{desc}</pre>
		</div>
		<div className="programm-tab-content-item__speaker">{speaker}</div>
	</div>
))

import { createSlice } from '@reduxjs/toolkit'

import mainPageApi from '../../../api/mainpage-api'
import { addNot } from '../global-nots/global-nots'

const initialState = {
	mainNews: {
		text: '',
		url: '',
		active: false,
	},
	filters: {
		limit: 10,
		offset: 0,
	},
	actual: [],
	news: [],
	popular: [],
	offices: [],
	links: {
		vk: '',
		fb: '',
		yt: '',
		ig: '',
		ld: '',
	},
	isEmpty: false,
	isLoading: false,
}

const mainPage = createSlice({
	name: 'mainPage',
	initialState,
	reducers: {
		setMainNews: (state, action) => {
			state.mainNews = { ...state.mainNews, ...action.payload }
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setActual: (state, action) => {
			state.actual = action.payload.actual
		},

		setNews: (state, action) => {
			state.news = action.payload.news
		},

		setPopular: (state, action) => {
			state.popular = action.payload.popular
		},

		setOffices: (state, action) => {
			state.offices = action.payload.offices
		},

		setLinks: (state, action) => {
			state.links = { ...state.links, ...action.payload }
		},

		setIsEmpty: (state, action) => {
			state.isEmpty = action.payload.isEmpty
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
	},
})

const { reducer, actions } = mainPage

export const {
	setMainNews,
	setFilters,
	setActual,
	setNews,
	setPopular,
	setOffices,
	setLinks,
	setIsLoading,
	setIsEmpty,
} = actions

export const requestContentPage = () => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await mainPageApi.requestContentPage()

	if (res.success) {
		const checkInfo = !!Object.values(res.result)
			.flat()
			.find((item) => !!item)

		if (!checkInfo) dispatch(setIsEmpty({ isEmpty: true }))

		dispatch(
			setMainNews({
				text: res.result.text,
				url: res.result.header_url,
				active: res.result.active_header,
			})
		)

		dispatch(
			setLinks({
				vk: res.result.vk,
				fb: res.result.fb,
				yt: res.result.yt,
				ig: res.result.ig,
				ld: res.result.ld,
			})
		)

		dispatch(setOffices({ offices: res.result.actual_offices }))

		dispatch(setPopular({ popular: res.result.actual_products }))

		dispatch(setNews({ news: res.result.actual_news }))

		dispatch(setActual({ actual: res.result.actual_board }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

export default reducer

import { createSlice } from '@reduxjs/toolkit'

import commonApi from '../../../api/common-api'
import officesApi from '../../../api/offices-api'

import { addNot } from '../global-nots/global-nots'

const initialState = {
	officesList: [],
	officeById: {},
	cities: [],
	calendar: [],
	filters: {
		is_proposal: 0,
		equipment: [],
		limit: 0,
		offset: 0,
		city: '',
		month: new Date(new Date().getFullYear(), new Date().getMonth(), 15).toISOString(),
	},
	isLoading: false,
	isLoaded: false,
	failed: false,
}

const offices = createSlice({
	name: 'offices',
	initialState,
	reducers: {
		setOffices: (state, action) => {
			state.officesList = action.payload.officesList
		},
		setofficeById: (state, action) => {
			state.officeById = action.payload.officeById
		},
		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},
		setCities: (state, action) => {
			state.cities = action.payload.cities
		},
		setCalendar: (state, action) => {
			state.calendar = action.payload.calendar
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
		setIsLoaded: (state, action) => {
			state.isLoaded = action.payload.isLoaded
		},
		setFailed: (state, action) => {
			state.failed = action.payload.failed
		},
	},
})

const { reducer, actions } = offices

export const {
	setOffices,
	setofficeById,
	setFilters,
	setCities,
	setCalendar,
	setIsLoading,
	setIsLoaded,
	setFailed,
} = actions

// получение списка офисов
export const requestOffises = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		offices: { filters, isLoaded },
	} = getState()

	const res = await officesApi.requestOfficesList(filters)

	if (res.success) {
		dispatch(setOffices({ officesList: res.result.offices }))

		if (!isLoaded) {
			dispatch(setIsLoaded({ isLoaded: true }))
		}
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// получение офиса по id
export const requestOfficeById = (id, date) => async (dispatch) => {
	const res = await officesApi.requestOfficeById(id, date)

	if (res.success) {
		dispatch(setofficeById({ officeById: res.result }))
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// получение нерабочих и забронированных дней для календаря
export const requestOfficeCalendar = (id) => async (dispatch, getState) => {
	const {
		offices: { filters },
	} = getState()

	const res = await officesApi.requestOfficeCalendar(id, filters.month)

	if (res.success) {
		dispatch(setCalendar({ calendar: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// получение списка городов для фильтра
export const getCitiesForFilters = () => async (dispatch) => {
	const res = await commonApi.getCitiesForFilters(1)

	if (res.success) {
		dispatch(setCities({ cities: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// запрос на бронирование офиса
export const requestOfficeRequest = (id, startDay, endDay) => async (dispatch, getState) => {
	const {
		offices: { filters },
	} = getState()

	const res = await officesApi.requestOfficeRequest(id, startDay, endDay)

	if (res.success) {
		dispatch(requestOfficeCalendar(id, filters.month))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// запрос на отмену заявки бронирования на рассмотрении
export const rejectOfficeRequest = (id) => async (dispatch, getState) => {
	const {
		offices: { filters },
	} = getState()

	const res = await officesApi.rejectOfficeRequest(id)

	if (res.success) {
		dispatch(requestOfficeCalendar(id, filters.month))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}
export default reducer

import React, { useRef, useEffect } from 'react'

import './Popup.scss'

export const Popup = React.memo(({ children, title, onClose }) => {
	const ref = useRef(null)

	const hideModalKey = (event) => {
		if (event.key === 'Escape') {
			onClose(false)
		}
	}

	const hideModalClick = (event) => {
		if (ref.current && !ref.current.contains(event.target) && event.target.tagName !== 'IMG') {
			onClose(false)
		}
	}
	useEffect(() => {
		document.addEventListener('keydown', (e) => {
			hideModalKey(e)
		})

		document.addEventListener('click', (e) => {
			hideModalClick(e)
		})

		return () => {
			document.removeEventListener('keydown', (e) => {
				hideModalKey(e)
			})

			document.removeEventListener('click', (e) => {
				hideModalClick(e)
			})
		}
	})

	return (
		<div className="popup-plug">
			<div
				ref={ref}
				className="popup-wrapper">
				{children}
			</div>
		</div>
	)
})
import { createSlice } from '@reduxjs/toolkit'

import userFormApi from '../../../api/user-form-api'
import commonApi from '../../../api/common-api'

import { addNot } from '../global-nots/global-nots'
import { setAll } from '../local/local'
import { getCurrentUser } from '../profile/profile'
import { setCountryId } from '../search/search'
import { getPageFields } from './functions'

const initialState = {
	tel: null,
	isLoading: false,
	profile: {
		surname: '',
		name: '',
		patronymic: '',
		birthday: '',
		email: '',
		expIT: '',
		country: '',
		city: '',
		language: [],
		social: [],
		resumeLink: '',
		resumeFile: '',
		gitLink: '',
		about: '',
	},
	work: {
		workState: 0,
		organization: '',
		post: '',
		workOpen: false,
		motivation: '',
		workType: 0,
		country: [],
		city: [],
		monthCost: '',
		hourCost: '',
	},
	userSpecList: {},
	userTechList: {
		skills: {},
		other: [],
	},
	specsList: [],
	techList: [],
}

const userForm = createSlice({
	name: 'userForm',
	initialState,
	reducers: {
		setTel: (state, action) => {
			state.tel = action.payload.tel
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
		setProfile: (state, action) => {
			state.profile = { ...state.profile, ...action.payload }
		},
		setWork: (state, action) => {
			state.work = { ...state.work, ...action.payload }
		},
		setUserTechs: (state, action) => {
			state.userTechList.skills = action.payload.tech
		},
		setUserTechsOther: (state, action) => {
			state.userTechList.other = action.payload.other
		},
		setUserSpecs: (state, action) => {
			state.userSpecList = action.payload.specs
		},
		setSpecsList: (state, action) => {
			state.specsList = action.payload.specs
		},
		setTechsList: (state, action) => {
			state.techList = action.payload.tech
		},
		setSocialList: (state, action) => {
			state.profile.social = action.payload.social
		},
	},
})

const { reducer, actions } = userForm

export const {
	setTel,
	setProfile,
	setWork,
	setSpecsList,
	setTechsList,
	setPhoto,
	setTechsCustom,
	setIsLoading,
	setUserSpecs,
	setUserTechs,
	setUserTechsOther,
	setSocialList,
} = actions

// Получение информации для анкеты
export const getInfo = () => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await userFormApi.getInfo()

	if (res.success) {
		const {
			user: { tel, first_page, second_page, third_page, fourth_page },
			local,
		} = res.result

		dispatch(setAll({ local }))

		dispatch(setUserSpecs({ specs: third_page.specializations }))
		dispatch(setUserTechs({ tech: fourth_page.skills }))
		dispatch(setUserTechsOther({ other: Object.entries(fourth_page.other) }))

		dispatch(setTel({ tel }))

		dispatch(
			setProfile({
				surname: first_page.surname,
				name: first_page.name,
				patronymic: first_page.patron,
				birthday: first_page.birthday ? new Date(first_page.birthday) : '',
				email: first_page.email,
				expIT: first_page.exp_it,
				country: first_page.country,
				city: first_page.city,
				language: first_page.langs || [],
				social: first_page.social || [],
				resumeLink: first_page.resume_link,
				resumeFile: first_page.resume_file,
				gitLink: first_page.git_link,
				about: first_page.about,
			})
		)

		dispatch(
			setWork({
				workState: second_page.work_state,
				organization: second_page.comp,
				post: second_page.post,
				workOpen: second_page.work_open,
				motivation: second_page.motivation,
				workType: second_page.work_type,
				country: second_page.countries || [],
				city: second_page.cities || [],
				monthCost: second_page.month_cost,
				hourCost: second_page.hour_cost,
			})
		)

		dispatch(setCountryId({ countryId: first_page.country_id || null }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Сохранение информации в анкете
export const setInfo =
	(page, moderate = false) =>
	async (dispatch, getState) => {
		const data = getPageFields(page, getState())

		const res = await userFormApi.setInfo(page, data)

		if (res.success) {
			if (moderate) {
				await dispatch(setVerif())
			}

			if (page === 4 || moderate) {
				dispatch(getCurrentUser())
			}

			if (page === 4) {
				dispatch(addNot({ not: 'Сохранено', type: 'success' }))
			}

			dispatch(getInfo())
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}
	}

// Отправка на модерацию
const setVerif = () => async (dispatch) => {
	const res = await userFormApi.setVerif()

	if (!res.success) {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}
/* 
	хз, в апи нет такого 
*/
// export const setSkills = () => async (dispatch) => {
// 	const res = await userFormApi.setSkills()

// 	if (res.success) {
// 		dispatch(setUserSpecs({ specs: res.result.specializations }))
// 		dispatch(setUserTechs({ tech: res.result.techs }))
// 	} else {
// 		dispatch(addNot({ not: res.result.msg }))
// 	}
// }

export const setSkillsList = () => async (dispatch) => {
	const res = await commonApi.getLocal(4)

	if (res.success) {
		dispatch(setSpecsList({ specs: res.result.specializations }))
		dispatch(setTechsList({ tech: res.result.techs }))
	} else {
		dispatch(addNot({ not: res.result.msg }))
	}
}

export default reducer

import instance from './api'

const authApi = {
	async reg({ phone, cap = '', search }) {
		const _search = search ? `&${search}` : ''

		const response = await instance.post(`core/reg?tel=${phone}&cap=${cap}${_search}`)

		return response.data
	},

	async auth({ phone, cap = '' }) {
		const response = await instance.post(`core/auth?tel=${phone}&cap=${cap}`)

		return response.data
	},

	async verif({ phone, pin, data }) {
		const response = await instance.post(`core/verif/user?tel=${phone}&pin=${pin}`, data)

		return response.data
	},

	async refreshPin(phone, trigger) {
		const response = await instance.post(`core/refresh/pin?tel=${phone}&trigger=${trigger}`)

		return response.data
	},
}

export default authApi

import { getToken } from '../functions/localStorage'
import instance from './api'

const productsApi = {
	async requestProducts(filters) {
		const response = await instance.post(
			'/user/search/product',
			{
				...filters,
			},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)
		return response.data
	},

	async requestProduct(id) {
		const response = await instance.post(
			`/product/get?product_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)
		return response.data
	},

	async requestCertificate(id) {
		const response = await instance.post(
			`user/get/cert/props?props_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	}
}

export default productsApi
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import cn from 'classnames'

import { AppLayout } from '../../components/AppLayout/AppLayout'
import { FirstStepForm } from './FirstStepForm/FirstStepForm'
import { SecondStepForm } from './SecondStepForm/SecondStepForm'

import { getNextStep } from '../../store/reducers/auth/auth-selectors'

import { setNextStep, setPhone, setType } from '../../store/reducers/auth/auth'

import './RegistPage.scss'

export const RegistPage = ({ checkin, firstText, secondText }) => {
	const dispatch = useDispatch()

	const firstTextValue = (
		<pre className="checkin-text">
			Главный хак для трудоустройства и&nbsp;успешного развития&nbsp;&mdash;
			связи.
			<br />
			Мы&nbsp;&mdash; сообщество, которое связывает специалистов
			и&nbsp;руководителей из&nbsp;разных областей&nbsp;IT сектора.
			Зарегистрируйтесь в&nbsp;нашем сообществе и&nbsp;расскажите о&nbsp;своих
			способностях.
			<br />
			Участие в&nbsp;сообществе станет связующим звеном ваших талантов
			и&nbsp;наших ресурсов, для успешной реализации проектов и&nbsp;обмена
			опытом! <br />
			Получите доступ к&nbsp;инфраструктуре кластера, программе обучения,
			программе поиска работы и&nbsp;поддержки проектов, мероприятиям
			и&nbsp;бонусам.
		</pre>
	)

	const secondTextValue = (
		<pre className="checkin-text">
			Мы&nbsp;уверены, что главное условие успешного и&nbsp;быстрого старта
			карьеры IT-специалиста&nbsp;&mdash; всегда оставаться в&nbsp;тренде
			развития отрасли, использовать актуальные подходы и&nbsp;находиться
			в&nbsp;среде коммуникации и&nbsp;обмена опытом!
			<br />
			Зарегистрируйся в&nbsp;сообществе. Расскажи нам о&nbsp;своих навыках
			и&nbsp;получи доступ к&nbsp;релевантной программе обмена опытом, обучения
			и&nbsp;работы. А&nbsp;еще тебе станет доступна инфраструктура кластера,
			помощь в&nbsp;реализации проектов и&nbsp;бонусная программа участника!
		</pre>
	)

	const nextStep = useSelector(getNextStep)

	useEffect(
		() => () => {
			dispatch(setNextStep({ nextStep: false }))
			dispatch(setPhone({ phone: '' }))
			dispatch(setType({ type: null }))
		},
		[dispatch]
	)

	return (
		<AppLayout>
			<div className={cn('regist-page', { text: firstText || secondText })}>
				{checkin && (
					<Helmet>
						<script type="text/javascript">
							{`!function(){
							var t=document.createElement("script");
							t.type="text/javascript",
							t.async=!0,t.src="https://vk.com/js/api/openapi.js?169",
							t.onload=function(){
								VK.Retargeting.Init("VK-RTRG-1024675-dpJsu"),
								VK.Retargeting.Hit()
							},
							document.head.appendChild(t)}();`}
						</script>
						<noscript>
							{`<img
							src="https://vk.com/rtrg?p=VK-RTRG-1024675-dpJsu"
							style="position:fixed; left:-999px;"
							alt=""
						/>`}
						</noscript>
					</Helmet>
				)}

				{firstText && firstTextValue}
				{secondText && secondTextValue}
				<div className="form-container">
					{!nextStep ? <FirstStepForm checkin={checkin} /> : <SecondStepForm />}
				</div>
			</div>
		</AppLayout>
	)
}

import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

import { IntegrationsModalTitle as Title } from '../IntegrationsModalTitle/IntegrationsModalTitle'
import { IntegrationsModalSubtitle as Subtitle } from '../IntegrationsModalSubtitle/IntegrationsModalSubtitle'
import { IntegrationsModalButtons as Buttons } from '../IntegrationsModalButtons/IntegrationsModalButtons'
import { Preloader } from '../../../../../components/Preloader/Preloader'

import { addNot } from '../../../../../store/reducers/global-nots/global-nots'

import './IntegrationModalContent.scss'

export const IntegrationModalContent = React.memo(({ botKey, info, onClose }) => {
	const dispatch = useDispatch()
	const code = useRef()

	if (!botKey) return <Preloader />

	if (!info) return null

	const { title, icon, link, is_use: isUse } = info

	const copyText = () => {
		const text = code.current.textContent
		navigator.clipboard.writeText(text)
		dispatch(addNot({ not: 'Код скопирован в буфер обмена', type: 'success' }))
	}

	return (
		<div className="integration-modal-content">
			<Title label={`Интеграция c ${title}`} icon={icon} />
			<Subtitle text={`Используйте код для интеграции с ${title}`} />
			<div ref={code} className="integration-modal-content__code">
				{botKey}
			</div>
			<div onClick={copyText} className="integration-modal-content__copy-code">
				<div className="copy-icon">
					<i></i>
				</div>
				Скопировать код
			</div>

			{isUse && (
				<div className="integration-modal-content__success">Поздравляем, бот проинтегрирован</div>
			)}

			<Buttons submitText="Интегрировать" onCancelClick={onClose} link={link} success={isUse} />
		</div>
	)
})

import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import cn from 'classnames'

import './PaginationItem.scss'

export const PaginationItem = React.memo(({ anchor }) => {
	const [active, setActive] = useState(false)

	const location = useLocation()
	const hash = location.hash

	useEffect(() => {
		if (hash === anchor) setActive(true)
		else setActive(false)
	}, [hash, anchor])

	return (
		<div className="pagination-item-wrapper">
			<a href={`/${anchor}`} className={cn('pagination-item', { active })}>
				<span></span>
			</a>
		</div>
	)
})

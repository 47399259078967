import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import './EventItem.scss'

export const EventItem = React.memo(
	({
		id,
		image,
		type,
		city,
		title,
		moderating,
		approved,
		rejected,
		count,
		dateStart,
		dateEnd,
		past,
		planned,
	}) => (
		<Link to={`/events/${id}`} className={cn('events-item', { past })}>
			<div className="events-item__image" style={{ backgroundImage: `url(${image})` }}></div>
			<div className="events-item__info">
				<div className="events-item__title">{title}</div>
				<div className="events-item__subinfo-mobile">
					<div className="events-item__details">{type === 2 ? city : 'Онлайн'}</div>
					<div className="events-item__date">
						{dateEnd === dateStart ? (
							dateStart
						) : (
							<>
								<span>{` · ${dateStart} - ${dateEnd}`}</span>
							</>
						)}
					</div>
				</div>
				<div className="events-item__status">
					{past && !approved && (
						<div className="item-status item-status__count item-status__count--full">
							Событие прошло
						</div>
					)}
					{moderating && (
						<div className="item-status item-status__moderating">
							<i></i>
							Заявка на рассмотрении
						</div>
					)}
					{approved && (
						<div className="item-status item-status__approved">
							<i></i>
							{past ? 'Вы принимали участие' : 'Участие одобрено'}
						</div>
					)}
					{rejected && (
						<div className="item-status item-status__rejected">
							<i></i>
							Заявка отклонена
						</div>
					)}
					{!!count && !moderating && !approved && !rejected && !past && (
						<div className="item-status item-status__count">{`Осталось мест ${count}`}</div>
					)}
					{planned && !past && (
						<div className="item-status item-status__count item-status__count--full">
							Запланировано
						</div>
					)}
					{!moderating && !approved && !rejected && !count && !planned && !past && (
						<div className="item-status item-status__count item-status__count--full">
							Нет свободных мест
						</div>
					)}
				</div>
			</div>
			<div className="events-item__subinfo">
				{!planned && (
					<div className="events-item__date">
						{dateEnd === dateStart ? (
							dateStart
						) : (
							<>
								<span>{`${dateStart} - ${dateEnd}`}</span>
							</>
						)}
					</div>
				)}
				<div className="events-item__details">{type === 2 ? city : 'Онлайн'}</div>
			</div>
		</Link>
	)
)

import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'

import './CertificatesModalContent.scss'
import { CertificateContent } from './CertificateContent/CertificateContent'
import { Preloader } from '../Preloader/Preloader'

const PrintButton = () => (
	<div className="certificates-modal-content__print">
		<i></i>
		Печатать
	</div>
)

export const CertificatesModalContent = React.memo(({ certificate }) => {
	const certRef = useRef()

	if (!certificate) return <Preloader full />

	const { status } = certificate

	const used = status === 2
	const rejected = status === 3 || status === 4

	return (
		<div className="certificates-modal-content">
			<CertificateContent ref={certRef} certificate={certificate} />
			{!used && !rejected && (
				<ReactToPrint
					trigger={PrintButton}
					content={() => certRef.current}
					bodyClass="certificates-modal-content__print-window"
				/>
			)}
		</div>
	)
})

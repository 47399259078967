import { createSlice } from '@reduxjs/toolkit'
import partnersApi from '../../../api/partners-api'
import { addNot } from '../global-nots/global-nots'

const initialState = {
	partners: [],
	percent: null,
	inviteLink: '',
	count: 0,
	allCount: 0,
	activeCount: 0,
	point: 0,
	defPoint: 0,
	points: [],
	isLoading: false,
	filters: {
		offset: 0,
		limit: 10,
		name: '',
	},
	pointsHistory: [],
	historyFilters: {
		offset: 0,
		limit: 10,
	},
	historyCount: 0,
	historyLoaded: false,
}

const partners = createSlice({
	name: 'partners',
	initialState,
	reducers: {
		setPercent: (state, action) => {
			state.percent = action.payload.percent
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setInviteLink: (state, action) => {
			state.inviteLink = action.payload.link
		},

		setPartners: (state, action) => {
			state.partners = action.payload.partners
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setActiveCount: (state, action) => {
			state.activeCount = action.payload.count
		},

		setPoint: (state, action) => {
			state.point = action.payload.point
		},

		setDefPoint: (state, action) => {
			state.defPoint = action.payload.defPoint
		},

		setPoints: (state, action) => {
			state.points = action.payload.points
		},

		setAllCount: (state, action) => {
			state.allCount = action.payload.count
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setPointsHistory: (state, action) => {
			state.pointsHistory = action.payload.history
		},

		setHistoryCount: (state, action) => {
			state.historyCount = action.payload.count
		},

		setHistoryOffset: (state, action) => {
			state.historyFilters = {
				...state.historyFilters,
				offset: action.payload.offset,
			}
		},

		setHistoryLoaded: (state, action) => {
			state.historyLoaded = action.payload.loaded
		},
	},
})

const { reducer, actions } = partners

export const {
	setPercent,
	setInviteLink,
	setPartners,
	setCount,
	setIsLoading,
	setActiveCount,
	setPoint,
	setDefPoint,
	setPoints,
	setFilters,
	setAllCount,
	setPointsHistory,
	setHistoryCount,
	setHistoryOffset,
	setHistoryLoaded,
} = actions

/*
	Получение информации для партнера 
*/
export const getInfo = () => async (dispatch) => {
	const res = await partnersApi.getInfo()

	if (res.success) {
		dispatch(setPercent({ percent: res.result.percent }))
		dispatch(setAllCount({ count: res.result.count }))
		dispatch(setInviteLink({ link: res.result.invite_link }))
		dispatch(setActiveCount({ count: res.result.appr_count }))
		dispatch(setPoint({ point: res.result.point }))
		dispatch(setDefPoint({ defPoint: res.result.def_sell }))
		dispatch(setPoints({ points: res.result.points }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

/*
	Получение списка партнеров
*/
export const requestPartners = () => async (dispatch, getState) => {
	const {
		partners: { filters },
	} = getState()

	const res = await partnersApi.requestPartners(filters)

	if (res.success) {
		dispatch(setPartners({ partners: res.result.users }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

/*
	Получение истории начисления поинтов
*/
export const requestHistory = () => async (dispatch, getState) => {
	const {
		partners: { historyFilters: filters, historyLoaded: loaded },
	} = getState()

	const res = await partnersApi.requestPointsHistory(filters)

	if (res.success) {
		dispatch(setPointsHistory({ history: res.result.points }))
		dispatch(setHistoryCount({ count: res.result.count }))

		if (!loaded) {
			dispatch(setHistoryLoaded({ loaded: true }))
		}
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer

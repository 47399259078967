import React from 'react'
import cn from 'classnames'

import { Button } from '../../../../../components/Buttons/Button/Button'

import './IntegrationsModalButtons.scss'

export const IntegrationsModalButtons = React.memo(
	({
		submitText,
		type = 'md1',
		onCancelClick = () => {},
		onSubmitClick = () => {},
		red = false,
		success = false,
		link,
	}) => (
		<div
			className={cn('integration-modal-content__buttons', { single: success })}
		>
			{success ? (
				<Button label="Готово" onClick={onCancelClick} green />
			) : (
				<>
					<Button white label="Отменить" type={type} onClick={onCancelClick} />
					{red ? (
						<Button
							red={red}
							label={submitText}
							type={type}
							onClick={onSubmitClick}
						/>
					) : (
						<a href={link} target="_blank" rel="noopener noreferrer">
							<Button label={submitText} type={type} />
						</a>
					)}
				</>
			)}
		</div>
	)
)

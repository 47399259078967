import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

import { ModalContainer } from '../../../../components/ModalContainer/ModalContainer'

import { getDevice } from '../../../../store/reducers/app/app-selectors'

import icon from '../../../../assets/media/svg/mapicon.svg'

import './EventMap.scss'

export const EventMap = React.memo(({ x, y, address, comment, past }) => {
	const containerStyle = {
		width: '100%',
		height: '100%',
	}

	const device = useSelector(getDevice)

	const [modal, toggleModal] = useState(false)

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyBjxGLRKlyviwdZjI-yboRJW1pEUz-oCBI',
	})

	if (!x || !y) return

	return (
		<div className="event-map">
			{device === 'mobile' && (
				<div className="event-map__mobile-title">
					<div className="event-map__title">{past ? 'Где проходило' : 'Где пройдет'}</div>
				</div>
			)}
			<div className="event-map__container">
				{isLoaded ? (
					<GoogleMap
						mapContainerStyle={containerStyle}
						center={{ lat: x, lng: y }}
						zoom={16}
						options={{
							disableDefaultUI: true,
						}}
					>
						<Marker
							position={{ lat: x, lng: y }}
							icon={{
								url: icon,
								scaledSize: new window.google.maps.Size(35, 55),
							}}
						/>
					</GoogleMap>
				) : (
					<></>
				)}
			</div>
			{device === 'mobile' && (
				<div className="event-map__mobile-items">
					<span className="event-map__mobile-city">{address}</span>
					{comment && <span className="event-map__mobile-address-comment"> · {comment}</span>}
				</div>
			)}
			<div className="event-map__info">
				<div className="event-map__title-wrapper">
					<div className="event-map__title">{past ? 'Где проходило' : 'Где пройдет'}</div>
					{device !== 'mobile' && device !== 'notepad' && (
						<button className="event-map__popup" onClick={() => toggleModal(true)}>
							на карте
						</button>
					)}
				</div>
				<div className="event-map__address">
					<span>{address}</span>
					{comment && <span> · {comment}</span>}
				</div>
			</div>
			<ModalContainer show={modal} onClose={() => toggleModal(false)}>
				<div className="popup-map__container">
					{isLoaded ? (
						<GoogleMap
							mapContainerStyle={containerStyle}
							center={{ lat: x, lng: y }}
							zoom={16}
							options={{
								disableDefaultUI: true,
							}}
						>
							<Marker
								position={{ lat: x, lng: y }}
								icon={{
									url: icon,
									scaledSize: new window.google.maps.Size(35, 55),
								}}
							/>
						</GoogleMap>
					) : (
						<></>
					)}
				</div>
			</ModalContainer>
		</div>
	)
})

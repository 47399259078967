import instance from './api'

import { getToken } from '../functions/localStorage'

const teamFinderApi = {
	async getUsers(filters) {
		const response = await instance.post(
			'/recrut/search/user',
			{ ...filters },
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async getUserById(id) {
		const responce = await instance.post(
			`/recrut/get/user/info?user_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return responce.data
	},

	async buyUserInfo(id) {
		const responce = await instance.post(
			`/recrut/buy/user/info?user_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return responce.data
	},
}

export default teamFinderApi

export const getCertificates = ({ certificates: { certificates } }) => certificates

export const getValidCertificates = ({ certificates: { validCertificates } }) => validCertificates

export const getLimit = ({ certificates: { limit } }) => limit

export const getOffset = ({ certificates: { offset } }) => offset

export const getCount = ({ certificates: { count } }) => count

export const getStatus = ({ certificates: { status } }) => status

import instance from './api'

import { getToken } from '../functions/localStorage'

const profileApi = {
	async getCurrentUser() {
		const response = await instance.post(
			'user/get/shortinfo',
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async uploadPhoto(data) {
		const response = await instance.post('user/upload/photo', data, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
				'Content-Type': 'multipart/form-data',
			},
		})

		return response.data
	},

	async requestGroups() {
		const response = await instance.post(
			'user/get/groups',
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async requestHistory(offset) {
		const response = await instance.post(
			`user/get/trans?limit=10&offset=${offset}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async requestBots() {
		const response = await instance.post(
			'user/get/bots',
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async requestBotActiveKey(type) {
		const response = await instance.post(
			`user/get/activkey?type=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async disableBot(type) {
		const response = await instance.post(
			`user/delete/bot?bot_type=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},
}

export default profileApi

import React, { useState, useRef, useEffect } from 'react'
import { Slider } from 'antd'

import 'antd/dist/antd.css'
import './FilterRange.scss'

export const FilterRange = React.memo(({ value, setValue, maxValue }) => {
	const [sliderWidth, setSliderWidth] = useState(0)
	const [marginSlider, setMarginSlider] = useState(0)

	const valueWidth = useRef()

	let marginTooltip, marginMark

	if (typeof sliderWidth === 'string') {
		const marginTip = sliderWidth.split('%')[0]
		marginTooltip = marginTip / 2

		marginMark = marginSlider.split('%')[0]
	}

	useEffect(() => {
		if (valueWidth.current) {
			setSliderWidth(valueWidth.current.sliderRef.childNodes[1].style.width)
			setMarginSlider(valueWidth.current.sliderRef.childNodes[3].style.left)
		}
	}, [value, setSliderWidth, setMarginSlider])

	return (
		<div className="filter_range">
			<div
				className="tooltip"
				style={{
					marginLeft: `${marginTooltip < 20 && +marginMark < 18 ? '20' : marginTooltip}%`,
					left: `${+marginMark > 60 ? 'auto' : marginSlider}`,
					right: `${+marginMark > 60 ? '-31%' : 'auto'}`,
				}}
			>
				{`${!!value[0] ? value[0] : 0} - ${value[1]}`}
				<i></i>
			</div>
			<Slider
				range
				tipFormatter={null}
				value={value}
				defaultValue={[0, 0]}
				max={maxValue}
				onChange={(value) => setValue(value)}
				ref={valueWidth}
			/>
		</div>
	)
})

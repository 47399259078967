import React from 'react'
import { url } from '../../../api/api'
import cn from 'classnames'

import { createDate, getPlugNumber } from '../../../functions/common'

import { avatarPlug } from '../../Avatar/avatar-functions'

import './CommentBlock.scss'

export const CommentBlock = React.memo(({ commentInfo }) => {
	const timeOptions = {
		hour: 'numeric',
		minute: 'numeric',
	}

	const dateOptions = {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
	}

	const plugNumber = getPlugNumber(commentInfo.from_id)

	const imageUrl = commentInfo.from_image
		? `${url}/static/${commentInfo.from_image}`
		: avatarPlug(plugNumber)

	return (
		<div className={cn('comment-block', { delete: commentInfo.status === 3 })}>
			<div
				className={cn('comment-block__avatar avatar', {
					[`plug plug-${plugNumber}`]: !commentInfo.from_image,
				})}
			>
				<img src={imageUrl} alt="" />
			</div>
			<div className="comment-block__body">
				<div className="comment-block__header">
					<div className="comment-block__header__name">
						<p>{commentInfo.from_name}</p>
					</div>
					<div className="comment-block__header__time">
						<p>
							{`${createDate(commentInfo.time_create, 'ru', dateOptions)} в ${createDate(
								commentInfo.time_create,
								'ru',
								timeOptions
							)}`}
						</p>
					</div>
				</div>
				<div className="comment-block__text">
					<p className="text">{commentInfo.msg}</p>
				</div>
				{commentInfo.status === 3 && (
					<div className="delete-reason-text">
						Ваш коммментарий удален по причине: <p>{commentInfo.status_msg}</p>
					</div>
				)}
			</div>
		</div>
	)
})

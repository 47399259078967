import React from 'react'
import { ArrowLink } from '../../components/ArrowLink/ArrowLink'

import './Page404.scss'

export const Page404 = React.memo(() => {
    return (
        <div className='page-404'>
            <div className="page-404__header"><div className="page-404__header-logo"></div></div>
            <div className="page-404__left-column">
                <div className="page-404__left-column-vincent"></div>
            </div>
            <div className="page-404__right-column">
                <div className="page-404__right-column-title"><span>404</span></div>
                <div className="page-404__right-column-subtitle"><span>Не удалось найти страницу</span></div>
                <div className="page-404__right-column-text"><span>Тут небольшей текст описывающий возможные причины ошибки. В две строки</span></div>
                <ArrowLink link="/" text="На главную" />
            </div>
        </div>
    )
})
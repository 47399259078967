import React, { useEffect, useRef } from 'react'
import cn from 'classnames'

import { generateID } from '../../functions/common'

import './Input.scss'

export const Input = React.forwardRef(
	(
		{
			type = 'text',
			name,
			className,
			label,
			placeholder,
			value,
			onChange = () => {},
			onBlur = () => {},
			onFocus = () => {},
			onKeyUp = () => {},
			onClick = () => {},
			disabled,
			autofocus = false,
			offAutoComplete = false,
		},
		ref
	) => {
		const id = generateID('in-')

		const inputRef = useRef()

		useEffect(() => {
			if (autofocus) {
				inputRef.current.focus()
			}
		})

		return (
			<div className={cn('input-main-container', className)}>
				<input
					ref={inputRef}
					name={name}
					id={id}
					type={type}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					onFocus={onFocus}
					onKeyUp={onKeyUp}
					onClick={onClick}
					disabled={disabled}
					autoComplete={offAutoComplete ? 'off' : 'on'}
				/>
				{label && <label htmlFor={id}>{label}</label>}
			</div>
		)
	}
)

import { getToken } from '../functions/localStorage'
import instance from './api'

const certificateApi = {
	async requestCertificates({ limit, offset, status = 0 }) {
		const response = await instance.post(
			`user/get/cert?limit=${limit}&offset=${offset}&status=${status}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async approveCertificate(hash, password) {
		const response = await instance.post(
			`admin/set/approve/cert?hash=${hash}&password=${password}`,
			{},
			{}
		)

		return response.data
	},
}

export default certificateApi

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ru } from 'date-fns/locale'
import { DatePickerCalendar } from 'react-nice-dates'

import { isEventDate } from '../../functions'

import { getCalendarEvents } from '../../../../store/reducers/events/events-selectors'

import {
	requestCalendar,
	setFilters,
} from '../../../../store/reducers/events/events'

import './Calendar.scss'
import 'react-nice-dates/build/style.css'

export const Calendar = React.memo(({ date, setDate, month, setMonth }) => {
	const dispatch = useDispatch()

	const events = useSelector(getCalendarEvents)

	const handleMonthChange = (date) => {
		dispatch(
			setFilters({
				date_type: 'month',
				time: new Date(date.getFullYear(), date.getMonth(), 15),
			})
		)
		setMonth(date)
	}

	const modifiers = {
		event: (date) => isEventDate(events, date, 0),
		moderating: (date) => isEventDate(events, date, 1),
		approved: (date) => isEventDate(events, date, 2),
		rejected: (date) => isEventDate(events, date, 3),
	}

	const modifiersClassNames = {
		event: '-event',
		approved: '-approved',
		rejected: '-rejected',
		moderating: '-moderating',
	}

	const handleChangeDate = (date) => {
		setDate(date)
		dispatch(setFilters({ time: date, date_type: 'day' }))
	}

	useEffect(() => {
		dispatch(requestCalendar(month))
	}, [dispatch, month])

	return (
		<div className="calendar">
			<DatePickerCalendar
				date={date}
				onDateChange={handleChangeDate}
				month={month}
				onMonthChange={handleMonthChange}
				locale={ru}
				modifiers={modifiers}
				modifiersClassNames={modifiersClassNames}
				weekdayFormat={'eeeeee'}
			/>
		</div>
	)
})

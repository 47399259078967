import React from 'react'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'

import { Nav } from '../Nav/Nav'
import { Button } from '../../Button/Button'

import './BurgerMenu.scss'

export const BurgerMenu = React.memo(({ isMenuShow }) => {
	const history = useHistory()

	const handleLoginClick = () => {
		history.push('/login')
	}

	const handleRegClick = () => {
		history.push('/checkin')
	}
	return (
		<div className={cn('burger-menu', { show: isMenuShow })}>
			<Nav burger />
			<Button label="Войти" onclick={handleLoginClick} />
			<Button label="Присоединиться" onclick={handleRegClick} color="blue" />
		</div>
	)
})

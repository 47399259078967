import React from 'react'
import { Filter } from './Filter/Filter'

import { FilterSubtitles } from './FilterSubtitles/FilterSubtitles'

import './Filters.scss'

export const Filters = React.memo(
	({
		filter,
		reset = () => {},
		value,
		setValue,
		maxValue,
		subtitles,
		handler,
		isActive,
		title,
		checkbox,
	}) => (
		<div className="filters">
			<div className="filters-header">
				<div className="filters-header__title">Фильтры</div>
				<div className="filters-header__reset" onClick={reset}>
					<i></i>
					<span>Сбросить</span>
				</div>
			</div>
			<div className="filters-body">
				{title && <div className="filter__title">{title}</div>}
				{filter.map((item, index) => {
					const skillsList = item.tech_skills
						? item.tech_skills.map((item) => [item.key, item.value])
						: []

					return subtitles ? (
						<FilterSubtitles
							subtitleSection={item.key}
							subtitle={item.title}
							skillsList={skillsList}
							handler={handler}
							active={isActive}
							index={index}
							key={`${item.title}-${index}`}
						/>
					) : (
						<Filter
							checkbox={checkbox}
							active={isActive}
							handler={item.handler}
							key={`${item.title}-${index}`}
							title={item.title}
							items={item.items}
							range={item.range}
							value={value}
							setValue={setValue}
							maxValue={maxValue}
							style={!item.items.length}
						/>
					)
				})}
			</div>
		</div>
	)
)

import React from 'react'

import cn from 'classnames'

import './AvatarReader.scss'

export const AvatarReader = React.memo(({ small, image, onChange, reset }) => {
	const id = `avatar-${Math.random().toString(36).substring(2, 11)}`

	return (
		<div className={cn('avatar-file-reader', { small, image })}>
			<i />
			<input
				id={id}
				type="file"
				accept=".png, .jpg, .jpeg, .bmp"
				onChange={onChange}
				onClick={reset}
			/>
			<label className="avatar-file-reader__label" htmlFor={id} />
		</div>
	)
})

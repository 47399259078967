import React, { lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import { Page404 } from '../../pages/Page404/Page404'

import { getIsEmpty, getIsApproved } from '../../store/reducers/profile/profile-selectors'

const AboutPage = lazy(() => import('../../pages/AboutPage/AboutPage'))
const CertificatesPage = lazy(() => import('../../pages/CertificatesPage/CertificatesPage'))
const EventsPage = lazy(() => import('../../pages/EventsPage/EventsPage'))
const FormPage = lazy(() => import('../../pages/FormPage/FormPage'))
const WorkPage = lazy(() => import('../../pages/WorkPage/WorkPage'))
const CampusPage = lazy(() => import('../../pages/CampusPage/CampusPage'))
const MainPage = lazy(() => import('../../pages/MainPage/MainPage'))
const MarketPage = lazy(() => import('../../pages/MarketPage/MarketPage'))
const OfficesPage = lazy(() => import('../../pages/OfficesPage/OfficesPage'))
const KnowledgeBasePage = lazy(() => import('../../pages/KnowledgeBasePage/KnowledgeBasePage'))
const PartnersPage = lazy(() => import('../../pages/PartnersPage/PartnersPage'))
const TeamFinder = lazy(() => import('../../pages/PartnersPage/TeamFinder/TeamFinder'))
const ProfilePage = lazy(() => import('../../pages/ProfilePage/ProfilePage'))
const NewsPage = lazy(() => import('../../pages/NewsPage/NewsPage'))

export const PagesRouter = () => {
	const isEmpty = useSelector(getIsEmpty)
	const isApproved = useSelector(getIsApproved)

	if (isEmpty || !isApproved)
		return (
			<Suspense fallback={<div></div>}>
				<Switch>
					<Route exact path="/" render={() => <ProfilePage />} />
					<Route path="/form" render={() => <FormPage />} />
					<Route path="/about" render={() => <AboutPage />} />
					<Redirect from="*" to="/" />
				</Switch>
			</Suspense>
		)

	return (
		<Suspense fallback={<div></div>}>
			<Switch>
				<Route exact path="/" render={() => <MainPage />} />
				<Route exact path="/events/:id?" render={() => <EventsPage />} />
				<Route exact path="/work" render={() => <WorkPage />} />
				<Route exact path="/campus" render={() => <CampusPage />} />
				<Route path="/market/:id?" render={() => <MarketPage />} />
				<Route path="/offices/:id?" render={() => <OfficesPage />} />
				<Route path="/knowledge-base/:id?" render={() => <KnowledgeBasePage />} />
				<Route path="/partners" render={() => <PartnersPage />} />
				<Route path="/team-finder" render={() => <TeamFinder />} />
				<Route path="/profile" render={() => <ProfilePage />} />
				<Route path="/form" render={() => <FormPage />} />
				<Route path="/certificates/:id?" render={() => <CertificatesPage />} />
				<Route path="/about" render={() => <AboutPage />} />
				<Route path="/news/:id?" render={() => <NewsPage />} />
				<Redirect from="/login" to="/" />
				<Redirect from="/checkin" to="/" />

				<Route path="*" render={() => <Page404 />} />
			</Switch>
		</Suspense>
	)
}

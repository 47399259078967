import React from 'react'
import { Link } from 'react-router-dom'

import './ArrowLink.scss'

export const ArrowLink = React.memo(({ link, text }) => (
	<Link to={link} className="arrow-link">
		{text}
		<i></i>
	</Link>
))

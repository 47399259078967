import { createSlice } from '@reduxjs/toolkit'

import { wsUrl } from '../../../api/api'
import certificateApi from '../../../api/certificate-api'
import commonApi from '../../../api/common-api'

import { getToken } from '../../../functions/localStorage'

import { setIsAuth } from '../auth/auth'
import { setValidCertificates } from '../certificates/certificates'
import { setNewNotification } from '../notifications/notifications'
import { getCurrentUser, setBots, setUser } from '../profile/profile'
import { incNewCount } from '../notifications/notifications'
import { addNot } from '../global-nots/global-nots'
import appApi from '../../../api/app-api'

const initialState = {
	initialized: false,
	isLoading: false,
	device: 'desktop',
	ws: null,
	updating: false,
	privacyPolit: '',
	persDataConsent: '',
	specsList: [],
	techList: [],
}

const app = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setInitialized: (state, action) => {
			state.initialized = action.payload.initialized
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
		setDevice: (state, action) => {
			state.device = action.payload.device
		},
		setWs: (state, action) => {
			state.ws = action.payload.ws
		},
		setUpdating: (state, action) => {
			state.updating = action.payload.updating
		},
		setPrivacyPolit: (state, action) => {
			state.privacyPolit = action.payload
		},
		setPersDataConsent: (state, action) => {
			state.persDataConsent = action.payload
		},
		setSpecsList: (state, action) => {
			state.specsList = action.payload.specs
		},
		setTechsList: (state, action) => {
			state.techList = action.payload.tech
		},
	},
})

const { reducer, actions } = app

export const {
	setInitialized,
	setIsLoading,
	setWs,
	setUpdating,
	setPrivacyPolit,
	setPersDataConsent,
	setDevice,
	setSpecsList,
	setTechsList,
	setFromLink,
} = actions

/*
	Инициализация при запуске
	Проверяет авторизацию
*/
export const initialize = () => (dispatch) => {
	if (getToken()) {
		dispatch(getCurrentUser())
		dispatch(setIsAuth({ isAuth: true }))
	}

	dispatch(setInitialized({ initialized: true }))
}

/*
	Получение действующих сертификатов для отображения списка в хедере
*/
export const requestValidCertificates = () => async (dispatch) => {
	const res = await certificateApi.requestCertificates({
		limit: 0,
		offset: 0,
		status: 1,
	})

	if (res.success) {
		dispatch(setValidCertificates({ certs: res.result.certs }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

/*
	Веб-сокет соединение
*/
export const connectWs = () => async (dispatch, getState) => {
	const ws = new WebSocket(wsUrl)
	dispatch(setUpdating({ updating: true }))
	dispatch(setWs({ ws }))

	ws.onmessage = (m) => {
		const { type, data } = JSON.parse(m.data)

		if (type === 'cert') {
			dispatch(setValidCertificates({ certs: [data] }))
		}

		if (type === 'notif') {
			dispatch(setNewNotification({ not: data }))
			dispatch(incNewCount())
			dispatch(addNot({ not: data.msg }))
		}

		if (type === 'bot_activate') {
			const {
				profile: { bots },
			} = getState()

			const _bots = bots.map((item) => {
				if (item.type === data) {
					return { ...item, is_use: true }
				}

				return item
			})

			dispatch(setBots({ bots: _bots }))
		}

		if (type === 'new_user_status') {
			const {
				profile: { user },
			} = getState()

			const { status, status_msg } = data

			dispatch(setUser({ user: { ...user, status, status_msg } }))
		}
	}

	ws.onerror = () => {}

	ws.onclose = () => {
		if (getState().app.updating) {
			setTimeout(() => {
				dispatch(connectWs())
			}, 5000)
		}
	}

	ws.onopen = () => {
		ws.send(`Bearer ${getToken()}`)
	}
}

/*
	Получение политики конфеденциальности 
*/
export const requestPrivacyPolit = () => async (dispatch) => {
	const res = await commonApi.getLocal(2)

	if (res.success) {
		dispatch(setPrivacyPolit(res.result))
	}
}
/*
	Получение обработки пресональных данных
*/
export const requestPersDataConsent = () => async (dispatch) => {
	const res = await commonApi.getLocal(3)

	if (res.success) {
		dispatch(setPersDataConsent(res.result))
	}
}

/*
	Получение списка скиллов и технологий
 */
export const requesSkillsList = () => async (dispatch) => {
	const res = await commonApi.getLocal(4)

	if (res.success) {
		dispatch(setSpecsList({ specs: res.result.specializations }))
		dispatch(setTechsList({ tech: res.result.techs }))
	} else {
		dispatch(addNot({ not: res.result.msg }))
	}
}

/*
	Отправка формы "Свяжитесь с нами"
*/
export const sendContactUsForm = (data) => async (dispatch) => {
	const res = await appApi.sendContactUsForm(data)

	if (res.success) {
		dispatch(addNot({ not: 'Отправлено', type: 'success' }))
	} else {
		dispatch(addNot({ not: 'Не удалось', type: 'error' }))
	}
}

export const sendRefsConversion = (data) => async () => {
	await appApi.sendRefsConversion(data)
}

export default reducer

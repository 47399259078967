import React from 'react'
import cn from 'classnames'

import './Button.scss'

export const Button = React.memo(
	({ color = '', onclick, label, classname = '', size = '', icon }) => {
		return (
			<button className={cn('button', color, size, classname)} onClick={onclick}>
				<span>{label}</span>
				{!!icon && <i style={{ backgroundImage: `url(${icon})` }}></i>}
			</button>
		)
	}
)

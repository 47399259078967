import React, { forwardRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { addComment } from '../../../store/reducers/comments/comments'

import { Button } from '../../Buttons/Button/Button'
import { Textarea } from '../../Textarea/Textarea'

import './CommentTextareaBlock.scss'

export const CommentTextareaBlock = React.memo(
	forwardRef((props, ref) => {
		const dispatch = useDispatch()

		const [text, setText] = useState('')
		const [error, setError] = useState({
			result: false,
			text: '',
		})

		const addCommentFunc = () => {
			dispatch(addComment(text))
			ref.current.scrollTop = 0
			setText('')
		}

		const checkText = (value) => {
			const urlregex = /^(ftp|http|https):\/\/[^ "]+$/
			const emailregex = new RegExp(/.+@.+\..+/i)

			if (urlregex.test(value) || emailregex.test(value)) {
				setError({
					result: true,
					text: 'Сообщение содержит недопустимые выражения (почта или ссылка)',
				})
			} else if (value.length > 1000) {
				setError({
					result: true,
					text: 'Слишком много символов в сообщении',
				})
			} else {
				setError({ ...error, result: false })
			}

			setText(value)
		}

		return (
			<div className="comments-block__body">
				<div className="comments-block__textarea-block">
					<Textarea
						placeholder={'Ваш комментарий'}
						onChange={({ target: { value } }) => checkText(value)}
						value={text}
					/>
					{error.result && <p className="error-message">{error.text}</p>}
					<Button label={'Отправить'} disabled={error.result} onClick={addCommentFunc} />
				</div>
			</div>
		)
	})
)

import React from 'react'
import cn from 'classnames'

import { PaginationItem } from './PaginationItem/PaginationItem'

import './Pagination.scss'

export const Pagination = React.memo(({ classname }) => {
	return (
		<div className={cn('pagination', classname)}>
			<PaginationItem anchor="" />
			<PaginationItem anchor="#about" />
			<PaginationItem anchor="#profit" />
			<PaginationItem anchor="#profit2" />
			<PaginationItem anchor="#profit3" />
			<PaginationItem anchor="#profit4" />
			<PaginationItem anchor="#faq" />
			<PaginationItem anchor="#partnership" />
			<PaginationItem anchor="#contacts" />
		</div>
	)
})

import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '../../../Button/Button'
import { SocialIcons } from './SocialIcons/SocialIcons'

import './JoinUs.scss'

export const JoinUs = React.memo(() => {
	const history = useHistory()

	const handleRegClick = () => {
		history.push('/checkin')
	}

	return (
		<div className="main__join-us">
			<div className="join-us__content-block">
				<SocialIcons />
				<div className="join-us__title title">
					Ты IT-специалист <br />
					и хочешь всегда быть
					<br /> в центре событий?
				</div>
				<div className="join-us__subtitle text">
					<span className="bold">X-Cluster</span> – сообщество для IT-специалистов.
					<br />
					Создаем условия для твоего комфортного развития.
				</div>
				<Button color="blue" onclick={handleRegClick} label="Присоединиться" />
			</div>
		</div>
	)
})

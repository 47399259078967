import React, { useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import './HeaderStatus.scss'

export const HeaderStatus = React.memo(({ waiting, fail }) => {
	const [hover, setHover] = useState(false)

	const statusButton = useRef()

	const mobileMenuHandler = useCallback(
		(e) => {
			if (
				(statusButton.current && statusButton.current.contains(e.target)) ||
				e.target.closest('.header-status')
			) {
				return
			}

			setHover(false)
			document.removeEventListener('mousedown', mobileMenuHandler)
		},
		[statusButton]
	)

	useEffect(() => {
		if (hover) {
			document.addEventListener('mousedown', mobileMenuHandler)
		} else {
			document.removeEventListener('mousedown', mobileMenuHandler)
		}
	}, [hover, mobileMenuHandler])

	return (
		<div
			className={cn('header-status', { waiting, fail }, { hover })}
			ref={statusButton}
			onClick={() => setHover(!hover)}
		>
			<div className="header-status__icon">
				<i></i>
			</div>
			{waiting && <span>Анкета на модерации</span>}
			{fail && <span>Анкета не прошла модерацию</span>}

			<div className="drop-card">
				{waiting && <span>Анкета на модерации</span>}
				{fail && <span>Анкета не прошла модерацию</span>}
			</div>
		</div>
	)
})

export const getPageFields = (
	page,
	{ userForm: { photo, profile, work, userSpecList, userTechList }, search: { countryId } }
) => {
	const firstPage = {
		image: photo,
		surname: profile.surname,
		name: profile.name,
		patron: profile.patronymic,
		birthday: profile.birthday,
		email: profile.email,
		exp_it: profile.expIT,
		country: profile.country,
		city: profile.city,
		langs: profile.language.map((lang) => ({ id: lang.id, skill: lang.skill })),
		social: profile.social,
		resume_link: profile.resumeLink,
		resume_file: profile.resumeFile,
		git_link: profile.gitLink,
		about: profile.about,
		country_id: countryId,
	}

	const secondPage = {
		work_state: work.workState,
		comp: work.organization,
		post: work.post,
		work_open: work.workOpen,
		motivation: work.motivation,
		work_type: work.workType,
		countries: work.country.map((country) => country.id || country),
		cities: work.city.map((city) => city.id || city),
		month_cost: work.monthCost,
		hour_cost: work.hourCost,
	}

	const thirdPage = { specializations: userSpecList }

	const fourthPage = {
		skills: userTechList.skills,
		other: Object.fromEntries(userTechList.other.filter((item) => !!item[0])),
	}

	switch (page) {
		case 0:
			return firstPage

		case 1:
			return secondPage

		case 2:
			return thirdPage
		case 3:
			return fourthPage

		case 4:
		default:
			return {
				...firstPage,
				...secondPage,
				...thirdPage,
				...fourthPage,
			}
	}
}

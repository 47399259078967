import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import app from './reducers/app/app'
import auth from './reducers/auth/auth'
import profile from './reducers/profile/profile'
import userForm from './reducers/user-form/user-form'
import local from './reducers/local/local'
import search from './reducers/search/search'
import events from './reducers/events/events'
import certificates from './reducers/certificates/certificates'
import globalNots from './reducers/global-nots/global-nots'
import notifications from './reducers/notifications/notifications'
import partners from './reducers/partners/partners'
import aboutClub from './reducers/about/about'
import news from './reducers/news/news'
import products from './reducers/products/products'
import offices from './reducers/offices/offices'
import mainPage from './reducers/mainPage/mainPage'
import teamFinder from './reducers/team-finder/team-finder'
import knowledgeBase from './reducers/knowledge-base/knowledge-base'
import comments from './reducers/comments/comments'

const reducers = {
	app,
	auth,
	profile,
	userForm,
	local,
	search,
	events,
	certificates,
	globalNots,
	notifications,
	partners,
	aboutClub,
	news,
	products,
	offices,
	mainPage,
	teamFinder,
	knowledgeBase,
	comments,
}

const middleware = getDefaultMiddleware({
	immutableCheck: false,
	serializableCheck: false,
	thunk: true,
})

const store = configureStore({ reducer: reducers, middleware })

window.store = store

export default store

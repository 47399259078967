export const questionnaireSubtitleText1 = 'Добро пожаловать в IT-Сообщество X-Cluster!'
export const questionnaireSubtitleText2 =
	'Для того, чтобы воспользоваться всеми преимуществами нашей платформы, Вам осталось заполнить анкету.'
export const questionnaireSubtitleText3 =
	'P.S. Каждая анкета проходит модерацию, поэтому убедительная просьба, заполняйте все поля максимально подробно. Это также поможет Вам в будущем в подборе правильного обучения и работы.'

export const groupsSubtitleText =
	'Участие в группах позволяет получить больше возможностей. Для того, чтобы попасть в группы, будьте активным участником сообщества.'

export const integrationsSubtitleText = 'Здесь Вы можете интегрировать ботов соцсетей'

export const integrationsModalTitle =
	'Для продолжения работы на платформе Вам необходимо произвести интеграцию с одним из предлагаемых ботов'

export const integrationsModalText =
	'Эта интеграция позволит Вам оперативно получать важные информационные сообщения от платформы, быстро восстанавливать доступ к аккаунту и выполнять ряд важных функций'

export const questionnaireWaitingStatusText =
	'Ваша анкета на модерации. Услуги платформы и возможность редактировать данные недоступны'

export const questionnaireFailStatusText =
	'Анкета не прошла модерцию. Вы можете отредактировать и повторно подать заявку'

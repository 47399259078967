import React from 'react'
import cn from 'classnames'

import './Preloader.scss'

export const Preloader = ({ full = false }) => {
	return (
		<div className={cn('preloader-component', { full })}>
			<div className="preloader"></div>
		</div>
	)
}

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import DOMPurify from 'dompurify'

import { ActiveText } from '../ActiveText/ActiveText'
import { ModalContainer } from '../ModalContainer/ModalContainer'

import { getPersDataConsent, getPrivacyPolit } from '../../store/reducers/app/app-selectors'

import './PrivacyPolicy.scss'

export const PrivacyPolicy = React.memo(({ links }) => {
	const [showModal, setShowModal] = useState(false)
	const [modalContent, setModalContent] = useState('')

	const privacyPolit = useSelector(getPrivacyPolit)
	const persDataConsent = useSelector(getPersDataConsent)

	const sanitazedText = DOMPurify.sanitize(modalContent)

	const modalClickHandler = (content) => () => {
		setShowModal(true)
		setModalContent(content)
	}

	return (
		<div className={cn('privacy-policy', { 'privacy-policy__links': links })}>
			<p className="check-text">
				{!links && <span className="privacy-policy-check">Я прочитал и принимаю&nbsp;</span>}
				<ActiveText onClick={modalClickHandler(privacyPolit)}>
					{links ? 'Политика ' : 'Политику '}
					конфиденциальности
				</ActiveText>
				{!links && <span className="privacy-policy-check">&nbsp;и даю согласие на&nbsp;</span>}
				<ActiveText onClick={modalClickHandler(persDataConsent)}>
					{links ? 'Обработка ' : 'Обработку '}
					персональных данных
				</ActiveText>
			</p>
			<ModalContainer
				show={showModal}
				onClose={() => {
					setShowModal(false)
					setModalContent('')
				}}
			>
				<div
					className="privacy-policy__content"
					dangerouslySetInnerHTML={{ __html: sanitazedText }}
				></div>
			</ModalContainer>
		</div>
	)
})

import React from 'react'
import { ProgrammTab } from './ProgrammTab/ProgrammTab'

import './ProgrammTabs.scss'

export const ProgrammTabs = React.memo(({ tabs, active, setActive }) => {
	const handleActiveChage = (index) => () => {
		const _activeTabs = [active].fill(false)
		_activeTabs[index] = true

		setActive(_activeTabs)
	}

	return (
		<div className="event-programm-tabs">
			{tabs.map((tab, index) => (
				<ProgrammTab
					key={`${tab}-${index}`}
					text={tab}
					active={active[index]}
					onClick={handleActiveChage(index)}
				/>
			))}
		</div>
	)
})

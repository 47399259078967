import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Header } from './Header/Header'
import { SectionContainer } from './SectionContainer/SectionContainer'
import { Pagination } from './Pagination/Pagination'
import { Main } from './Sections/Main/Main'
import { About } from './Sections/About/About'
import { ProfitFirst } from './Sections/Profit/ProfitFirst/ProfitFirst'
import { ProfitSecond } from './Sections/Profit/ProfitSecond/ProfitSecond'
import { ProfitThird } from './Sections/Profit/ProfitThird/ProfitThird'
import { ProfitFourth } from './Sections/Profit/ProfitFourth/ProfitFourth'
import { Faq } from './Sections/Faq/Faq'
import { Partner } from './Sections/Partner/Partner'
import { Contacts } from './Sections/Contacts/Contacts'

import './Landing.scss'

export const Landing = React.memo(() => {
	const [headerStyle, setHeaderStyle] = useState('color')
	const [paginationStyle, setPaginationStyle] = useState('color')

	const refLanding = useRef()
	const refMain = useRef()
	const refAbout = useRef()
	const refProfit = useRef()
	const refProfitSecond = useRef()
	const refProfitThird = useRef()
	const refProfitFourth = useRef()
	const refFaq = useRef()
	const refPartner = useRef()
	const refContats = useRef()

	const history = useHistory()

	useEffect(() => {
		const landing = refLanding.current
		const main = refMain.current
		const about = refAbout.current
		const profit = refProfit.current
		const profitSecond = refProfitSecond.current
		const profitThird = refProfitThird.current
		const profitFourth = refProfitFourth.current
		const faq = refFaq.current
		const partner = refPartner.current
		const contacts = refContats.current

		const refList = [
			main,
			about,
			profit,
			profitSecond,
			profitThird,
			profitFourth,
			faq,
			partner,
			contacts,
		]
		const idList = refList.map((item) => item.id)

		const sectionTracker = () => {
			refList.forEach((item, i) => {
				const id = item.id
				const hash = history.location.hash

				if (
					item.getBoundingClientRect().y < 40 &&
					item.getBoundingClientRect().y > -item.clientHeight + 40
				) {
					if (i === 1 || i === 3 || i === 7) {
						setHeaderStyle('white')
					} else {
						setHeaderStyle('color')
					}
				}
				if (
					item.getBoundingClientRect().y < item.clientHeight / 2 + 65 &&
					item.getBoundingClientRect().y > -item.clientHeight / 2 - 65
				) {
					if (i === 1 || i === 3 || i === 7) {
						setPaginationStyle('white')
					} else {
						setPaginationStyle('color')
					}
				}
				if (
					item.getBoundingClientRect().y < 1 &&
					item.getBoundingClientRect().y > -item.clientHeight / 3
				) {
					idList.forEach((sectionId) => {
						if (id === 'main') {
							if (hash) {
								history.push('')
							}
						} else if (id === sectionId) {
							if (hash !== `#${sectionId}`) {
								history.push(`#${sectionId}`)
							}
						}
					})
				}
			})
		}

		if (
			landing &&
			main &&
			about &&
			profit &&
			profitSecond &&
			profitThird &&
			profitFourth &&
			faq &&
			partner &&
			contacts
		) {
			landing.addEventListener('scroll', sectionTracker)
		}

		return () => {
			if (
				landing &&
				main &&
				about &&
				profit &&
				profitSecond &&
				profitThird &&
				profitFourth &&
				faq &&
				partner &&
				contacts
			) {
				landing.removeEventListener('scroll', sectionTracker)
			}
		}
	}, [
		history,
		refLanding,
		refMain,
		refAbout,
		refProfit,
		refFaq,
		refPartner,
		refContats,
		refProfitSecond,
		refProfitThird,
		refProfitFourth,
	])

	return (
		<div className="landing" ref={refLanding}>
			<Header classname={headerStyle} />
			<SectionContainer ref={refMain} id="main" classname="main-section__wrapper">
				<Main />
			</SectionContainer>
			<SectionContainer ref={refAbout} id="about" classname="about-secton__wrapper">
				<About />
			</SectionContainer>
			<SectionContainer ref={refProfit} id="profit" classname="profit-secton__wrapper">
				<ProfitFirst />
			</SectionContainer>
			<SectionContainer
				ref={refProfitSecond}
				id="profit2"
				classname="profit-second-secton__wrapper"
			>
				<ProfitSecond />
			</SectionContainer>
			<SectionContainer ref={refProfitThird} id="profit3">
				<ProfitThird />
			</SectionContainer>
			<SectionContainer
				ref={refProfitFourth}
				id="profit4"
				classname="profit-secton-fourth__wrapper"
			>
				<ProfitFourth />
			</SectionContainer>
			<SectionContainer ref={refFaq} id="faq" classname="faq-secton__wrapper">
				<Faq />
			</SectionContainer>
			<SectionContainer ref={refPartner} id="partnership" classname="partner-secton__wrapper">
				<Partner />
			</SectionContainer>
			<SectionContainer ref={refContats} id="contacts" classname="contacts-secton__wrapper">
				<Contacts />
			</SectionContainer>
			<Pagination classname={paginationStyle} />
		</div>
	)
})

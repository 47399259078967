import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'

import { Button } from '../../../components/Buttons/Button/Button'
import { LightButton } from '../../../components/Buttons/LightButton/LightButton'

import EventsContext from '../EventsContext'

import certificate from '../../../assets/media/svg/certificates.svg'

export const EventButtons = React.memo(
	({ status, count, cert, past, id, cancelEvent = () => {}, applyEvent = () => {}, statusMsg }) => {
		const dispatch = useDispatch()

		const { openCertificateModal } = useContext(EventsContext)

		const noApps = !!(status === 0 && count)
		const moderating = status === 1
		const approved = status === 2
		const rejected = status === 3

		return (
			<div className="event__buttons">
				{moderating && !past && (
					<div className=" event__status event__status--moderating">
						<i></i>На рассмотрении
					</div>
				)}

				{moderating && !past && (
					<LightButton
						red
						label="Отменить заявку"
						className="event-light-button"
						onClick={() => dispatch(cancelEvent(id))}
					/>
				)}

				{approved && (
					<div className="event__status event__status--approved">
						<i></i>
						{past ? 'Вы принимали участие' : 'Участие одобрено'}
					</div>
				)}
				{approved && cert && !past && (
					<LightButton
						label="Сертификат"
						className="event-light-button"
						onClick={openCertificateModal}
						image={certificate}
					/>
				)}

				{rejected && !past && (
					<div className="event__status event__status--rejected">
						<i></i>
						<span>Заявка отклонена</span>
						<span>{statusMsg}</span>
					</div>
				)}

				{noApps && !past && (
					<Button label="Зарегистрироваться" onClick={() => dispatch(applyEvent(id))} />
				)}
			</div>
		)
	}
)

import React from 'react'

import { NavLink } from 'react-router-dom'

import './NavItem.scss'

export const NavItem = React.memo(
	({ index, icon, text, link, exact = false }) => {
		const style = {
			backgroundImage: `url(${icon})`,
		}
		return (
			<NavLink
				className={`nav-item nav-item--color-${index}`}
				activeClassName="nav-item--active"
				exact={exact}
				to={link}
			>
				<div className="icon">
					<i style={style}></i>
				</div>
				<span>{text}</span>
			</NavLink>
		)
	}
)

export const getIsAuth = ({ auth: { isAuth } }) => isAuth

export const getNextStep = ({ auth: { nextStep } }) => nextStep

export const getNoKey = ({ auth: { noKey } }) => noKey

export const getPhone = ({ auth: { phone } }) => phone

export const getType = ({ auth: { type } }) => type

export const getPresence = ({ auth: { presence } }) => presence

import React from 'react'
import cn from 'classnames'

import './PointsCounter.scss'

export const PointsCounter = React.memo(
	({ increase = false, decrease = false, white = false, count = 0, withIcon = true, market }) => {
		const formatedCount = count.toLocaleString('ru')
		const _count = increase ? `+${formatedCount}` : decrease ? `-${formatedCount}` : formatedCount

		return (
			<div className={cn('points-counter', { increase, decrease, white }, { market: market })}>
				<div className={'points-counter__count'}>{_count}</div>
				{withIcon && (
					<div className="points-counter__icon">
						<i></i>
					</div>
				)}
			</div>
		)
	}
)

import instance from './api'

import { getToken } from '../functions/localStorage'

const getLocalString = (type) => {
	switch (type) {
		case 1:
			return 'faq'

		case 2:
		default:
			return 'pp'

		case 3:
			return 'pd'

		case 4:
			return 'skill'
	}
}

const commonApi = {
	async getCitiesForFilters(type) {
		/* type = 1 - офисы, 2 - события, 3 - товары */

		const response = await instance.post(
			`support/city/filter?type=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async getCountriesForFilters(type) {
		/* type = 1 - офисы, 2 - события, 3 - товары */

		const response = await instance.post(
			`support/country/filter?type=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async getLocal(type) {
		const response = await instance.post(
			`support/translate?type=${getLocalString(type)}&lang=ru`
		)

		return response.data
	},
}

export default commonApi

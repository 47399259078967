import React from 'react'
import cn from 'classnames'

import './Menu.scss'

export const Menu = ({ children, mobileMenu, right, menuRef }) => {
	return (
		<aside className={cn('app-side-menu', { mobileMenu, right })} ref={menuRef}>
			{children}
		</aside>
	)
}

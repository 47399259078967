import React, { useEffect, useState } from 'react'

import './MessageTimeout.scss'

export const MessageTimeout = React.memo(({ text, setTimer }) => {
	const [time, setTime] = useState(60)
	const [timerId, setTimerId] = useState()

	// Установка таймера
	useEffect(() => {
		setTimerId(
			setInterval(() => {
				setTime((t) => t - 1)
			}, 1000)
		)
	}, [])

	// Размонтировать, если таймер равен 0
	useEffect(() => {
		if (time === 0) {
			setTimer(false)
		}
	}, [time, setTimer])

	// Очистка таймера
	useEffect(() => {
		return () => {
			clearInterval(timerId)
		}
	}, [timerId])

	return <div className="message-timeout">{`${text} через ${time} секунд`}</div>
})

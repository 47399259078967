import React, { useRef } from 'react'
import { Formik, Form, Field } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import PhoneInput from 'react-phone-input-2'

import { RegistForm } from '../RegistForm/RegistForm'
import { Button } from '../../../components/Buttons/Button/Button'
import { Checkbox } from '../../../components/Checkbox/Checkbox'
import { FormGroup } from '../RegistForm/FormGroup/FormGroup'
import { FormHeader } from '../RegistForm/FormHeader/FormHeader'
import { PrivacyPolicy } from '../../../components/PrivacyPolicy/PrivacyPolicy'

import { getDevice } from '../../../store/reducers/app/app-selectors'

import { regAuth, setNextStep } from '../../../store/reducers/auth/auth'

import 'react-phone-input-2/lib/style.css'
import './FirstStepForm.scss'

export const FirstStepForm = React.memo(({ checkin }) => {
	const dispatch = useDispatch()
	const history = useHistory()

	const device = useSelector(getDevice)

	const { search } = useLocation()
	const _search = search.slice(1)

	const onChangeCap = (setValues) => (cap) => {
		setValues((values) => ({ ...values, cap }))
	}

	const captcha = useRef()

	const validate = (values) => {
		const { phone } = values
		const errors = {}

		if (phone.length < 11) {
			// errors.phone = 'Введите корректный номер телефона'
		}

		return errors
	}

	return (
		<Formik
			initialValues={{ phone: '', check: false }}
			validate={validate}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true)

				const { phone, check, cap } = values

				if (checkin) {
					if (!(phone && check && cap)) return
				} else {
					if (!(phone && cap)) return
				}

				const success = await dispatch(
					regAuth({ phone: phone.replace(/\D+/g, ''), cap, search: _search }, checkin)
				)

				setSubmitting(false)

				if (!success) {
					captcha.current.reset()
				} else {
					dispatch(setNextStep({ nextStep: true }))
				}
			}}
		>
			{({ values, isSubmitting, setValues }) => {
				let disableButtonReason

				if (checkin) {
					const { phone, check, cap } = values
					disableButtonReason = !(phone && check && cap)
				} else {
					const { phone, cap } = values
					disableButtonReason = !(phone && cap)
				}

				return (
					<Form>
						<RegistForm checkin>
							<FormHeader text={checkin ? 'Зарегистрироваться' : 'Войти'} />
							<FormGroup>
								<Field label="Ваш телефон" name="phone">
									{({ field: { name }, form: { setFieldTouched, setFieldValue } }) => (
										<PhoneInput
											country={'ru'}
											containerClass="phone-input-container"
											inputClass="phone-input"
											buttonClass="phone-input-button"
											dropdownClass="phone-input-dropdown"
											preferredCountries={['ru', 'ua', 'by', 'kz']}
											onBlur={() => {
												setFieldTouched(name, true)
											}}
											onChange={(value) => {
												setFieldValue(name, value)
											}}
										/>
									)}
								</Field>
								{checkin && (
									<div className="check-container">
										<Field name="check" as={Checkbox} />
										<PrivacyPolicy />
									</div>
								)}
								<ReCAPTCHA
									ref={captcha}
									size={device === 'mobile' ? 'compact' : 'normal'}
									sitekey="6LcbtUkaAAAAABWk4URG8gZ7cvFP-6Zbou_7WESs"
									onChange={onChangeCap(setValues)}
								/>
							</FormGroup>
							<Button
								nType="submit"
								label={checkin ? 'Зарегистрироваться' : 'Отправить'}
								type="md2"
								disabled={disableButtonReason || isSubmitting}
							/>
							{!checkin && (
								<span className="register__form">
									или &nbsp;
									<button
										className="register__form-button"
										onClick={() => {
											history.push('/checkin')
										}}
									>
										зарегистрироваться
									</button>
								</span>
							)}
						</RegistForm>
					</Form>
				)
			}}
		</Formik>
	)
})

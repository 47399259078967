import React, { useState } from 'react'
import { Tooltip } from '../../Tooltip/Tooltip'

import { getStatusByNumber } from '../../../functions/common'

import { statusTooltipText } from '../constants'

import './Status.scss'

export const Status = React.memo(({ status }) => {
	const [tooltip, setTooltip] = useState(false)

	const handleTooltipHover = (value) => () => {
		setTooltip(value)
	}

	return (
		<div className="status">
			<span>{getStatusByNumber(status)}</span>
			<i
				onMouseEnter={handleTooltipHover(true)}
				onMouseLeave={handleTooltipHover(false)}
			>
				<Tooltip left show={tooltip}>
					{statusTooltipText(status)}
				</Tooltip>
			</i>
		</div>
	)
})

import React from 'react'

import './Textarea.scss'

const MAX_HEIGHT = 360

export const Textarea = ({
	type = 'text',
	name,
	placeholder,
	value,
	onChange,
	onBlur,
	disabled,
}) => {
	const keyUp = (e) => {
		if (e.target.style.height === `${MAX_HEIGHT}px`) return

		e.target.style.height = 'inherit'

		const { offsetHeight, scrollHeight } = e.target
		if (offsetHeight === scrollHeight + 2) return

		const computed = window.getComputedStyle(e.target)
		const height =
			parseInt(computed.getPropertyValue('border-top-width'), 10) +
			parseInt(computed.getPropertyValue('padding-top'), 10) +
			scrollHeight +
			parseInt(computed.getPropertyValue('padding-bottom'), 10) +
			parseInt(computed.getPropertyValue('border-bottom-width'), 10)

		e.target.style.height = `${height}px`
	}

	return (
		<textarea
			name={name}
			className="textarea-main"
			type={type}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			onKeyUp={keyUp}
			disabled={disabled}
		></textarea>
	)
}

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'

import { Menu } from '../../../components/AppLayout/Menu/Menu'
import { ModalContainer } from '../../../components/ModalContainer/ModalContainer'
import { ColumnsContainer } from '../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { RightColumn } from '../../../components/PageWrapper/ColumnsContainer/RightColumn/RightColumn'
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { Calendar } from './Calendar/Calendar'
import { Events } from './Events/Events'
import { Filters } from '../../../components/Filters/Filters'

import { getCities, getFilters } from '../../../store/reducers/events/events-selectors'
import { getDevice } from '../../../store/reducers/app/app-selectors'

import { setFilters } from '../../../store/reducers/events/events'
import { MobileFilters } from '../../../components/MobileFilters/MobileFilters'
import { Filter } from '../../../components/Filters/Filter/Filter'
import { Button } from '../../../components/Buttons/Button/Button'

export const EventsList = React.memo(() => {
	const dispatch = useDispatch()

	const cities = useSelector(getCities)
	const device = useSelector(getDevice)
	const { type, city, is_proposal } = useSelector(getFilters)

	const [date, setDate] = useState()
	const [rightPanel, openPanel] = useState(false)
	const [modal, openModal] = useState(false)
	const [selectedMenuItem, setSelectedMenuItem] = useState('')
	const [eventsType, setEventsType] = useState(0)
	const [month, setMonth] = useState(new Date(new Date().getFullYear(), new Date().getMonth()))
	const [filterID, setFilterID] = useState(0)
	const [filterValue, setFilterValue] = useState({})

	const handlerFilterValue = (value, flag) => () => {
		if (value === filterValue[flag])
			return setFilterValue({ ...filterValue, [flag]: flag === 'city' ? '' : 0 })
		return setFilterValue({ ...filterValue, [flag]: value })
	}

	// Возврат handler после проверки на device
	const setHandler = (value, flag, handler) => {
		if (device === 'mobile') return handlerFilterValue(value, flag)
		return handler(value)
	}

	const setActiveValue = (value, flag, checkValue) => {
		if (device === 'mobile') return filterValue[flag] === value
		return checkValue === value
	}

	// Функция для открытия модального окна и записи Index нажатой категории
	const setFilterItem = (id) => () => {
		openModal(true)
		setFilterID(id)
		setSelectedMenuItem('filter')
	}

	// Фильтр "Участие"
	const handleMyEventsFilter = () => () => {
		dispatch(setFilters({ is_proposal: Number(!is_proposal) }))
	}

	// Фильтр "Формат"
	const handleFormatFilter = (value) => () => {
		dispatch(setFilters({ type: type === value ? 0 : value }))
	}

	// Фильтр "Город"
	const handleCitiesFilter = (value) => () => {
		dispatch(setFilters({ city: city === value ? '' : value }))
	}

	// Фильтр "Даты"
	const handleDatesFilter = (value) => () => {
		setEventsType(eventsType === value ? 0 : value)
	}

	// Сбросить фильтр
	const handleResetFilter = () => {
		setEventsType(0)
		setFilterValue({})
		dispatch(setFilters({ type: 0, is_proposal: 0, city: '' }))
	}

	// Открытие бокового меню на планшетах и ноутбуках
	const openPanelHandler = (value) => {
		setSelectedMenuItem(value)
		openPanel(true)
	}

	// Открытие модального окна на мобилках
	const openModalhandler = (value) => {
		setSelectedMenuItem(value)
		openModal(true)
	}

	const setFiltersFunc = () => {
		const switchObj = {
			0: handleMyEventsFilter(filterValue.is_proposal),
			1: handleFormatFilter(filterValue.type),
			2: handleCitiesFilter(filterValue.city),
			3: handleDatesFilter(filterValue.eventsType),
		}

		openModal(false)

		return switchObj[filterID]()
	}

	// Вывод компонента в боковую панель
	const setPanelComponent = (selectedMenuItem) => {
		const switchObj = {
			calendar: <Calendar date={date} setDate={setDate} month={month} setMonth={setMonth} />,
			filter: <Filters filter={filters} reset={handleResetFilter} />,
		}

		return switchObj[selectedMenuItem]
	}

	// Вывод компонента в модальное окна
	const setModalComponent = (selectedMenuItem) => {
		const switchObj = {
			calendar: <Calendar date={date} setDate={setDate} month={month} setMonth={setMonth} />,
			filter: (
				<>
					<Filter
						key={`${filters[filterID].title}-${filterID}`}
						title={filters[filterID].title}
						items={filters[filterID].items}
						setValue={handlerFilterValue()}
					/>

					<div className="button-block">
						<Button
							label="Применить"
							onClick={setFiltersFunc}
							type="md2"
							className="acceptFilter"
						/>
					</div>
				</>
			),
		}

		return switchObj[selectedMenuItem]
	}

	const filters = [
		{
			title: 'Участие',
			items: [
				{
					title: 'Мои события',
					handler: setHandler(1, 'is_proposal', handleMyEventsFilter),
					active: setActiveValue(1, 'is_proposal', is_proposal),
				},
			],
		},
		{
			title: 'Формат',
			items: [
				{
					title: 'Онлайн',
					handler: setHandler(1, 'type', handleFormatFilter),
					active: setActiveValue(1, 'type', type),
				},
				{
					title: 'Офлайн',
					handler: setHandler(2, 'type', handleFormatFilter),
					active: setActiveValue(2, 'type', type),
				},
			],
		},
		{
			title: 'Город',
			items: cities.map((item) => ({
				title: item,
				handler: setHandler(item, 'city', handleCitiesFilter),
				active: setActiveValue(item, 'city', city),
			})),
		},
		{
			title: 'Даты',
			items: [
				{
					title: 'Прошедшие',
					handler: setHandler(1, 'eventsType', handleDatesFilter),
					active: setActiveValue(1, 'eventsType', eventsType),
				},
				{
					title: 'Текущие',
					handler: setHandler(2, 'eventsType', handleDatesFilter),
					active: setActiveValue(2, 'eventsType', eventsType),
				},
				{
					title: 'Предстоящие',
					handler: setHandler(3, 'eventsType', handleDatesFilter),
					active: setActiveValue(3, 'eventsType', eventsType),
				},
			],
		},
	]

	return (
		<>
			<PageWrapper>
				<div className="mobile-icons-block">
					<h2>События</h2>
					<div className="icons-block">
						<div className="calendar-image" onClick={() => openModalhandler('calendar')}>
							<i />
						</div>
					</div>
				</div>
				{device === 'mobile' && (
					<MobileFilters filters={filters} reset={handleResetFilter} setFilterID={setFilterItem} />
				)}
				<ColumnsContainer>
					<LeftColumn>
						<Events type={eventsType} setDate={setDate} month={month} />
					</LeftColumn>
					<RightColumn adaptive>
						<Panel>
							<Calendar date={date} setDate={setDate} month={month} setMonth={setMonth} />
						</Panel>
						<Panel>
							<Filters filter={filters} reset={handleResetFilter} />
						</Panel>
					</RightColumn>
				</ColumnsContainer>
			</PageWrapper>
			<Menu mobileMenu right>
				<div
					className={cn('calendar-image', {
						active: selectedMenuItem === 'calendar',
					})}
					onClick={() => openPanelHandler('calendar')}
				>
					<i />
				</div>
				{device !== 'mobile' && (
					<div
						className={cn('filter-image', {
							active: selectedMenuItem === 'filter',
						})}
						onClick={() => openPanelHandler('filter')}
					>
						<i />
					</div>
				)}
			</Menu>
			<Panel right openTrigger={rightPanel} openFunc={openPanel}>
				{setPanelComponent(selectedMenuItem)}
			</Panel>
			<ModalContainer show={modal} onClose={() => openModal(false)}>
				{setModalComponent(selectedMenuItem)}
			</ModalContainer>
		</>
	)
})

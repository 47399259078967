export const getInitialized = ({ app: { initialized } }) => initialized

export const getIsLoading = ({ app: { isLoading } }) => isLoading

export const getPrivacyPolit = ({ app: { privacyPolit } }) => privacyPolit

export const getPersDataConsent = ({ app: { persDataConsent } }) => persDataConsent

export const getDevice = ({ app: { device } }) => device

export const getSpecsList = ({ app: { specsList } }) => specsList

export const getTechsList = ({ app: { techList } }) => techList

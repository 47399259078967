import React, { useCallback, useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Header } from '../Header/Header'
import { Menu } from './Menu/Menu'
import { ProfileBlock } from '../ProfileBlock/ProfileBlock'
import { Nav } from './Menu/Nav/Nav'

import { getDevice } from '../../store/reducers/app/app-selectors'
import { getIsAuth } from '../../store/reducers/auth/auth-selectors'

import './AppLayout.scss'

export const AppLayout = ({ children }) => {
	const location = useLocation()

	const mobileMenuRef = useRef()

	const isAuth = useSelector(getIsAuth)

	const device = useSelector(getDevice)

	const [mobileMenu, setMobileMenu] = useState(false)

	const mobileMenuHandler = useCallback(
		(e) => {
			if (
				(mobileMenuRef.current && mobileMenuRef.current.contains(e.target)) ||
				e.target.closest('.mobile-menu-button') ||
				e.target.closest('a')
			) {
				return
			}

			setMobileMenu(false)
			document.removeEventListener('mousedown', mobileMenuHandler)
		},
		[mobileMenuRef]
	)

	useEffect(() => {
		if (mobileMenu) {
			document.addEventListener('mousedown', mobileMenuHandler)
		} else {
			document.removeEventListener('mousedown', mobileMenuHandler)
		}
	}, [mobileMenu, mobileMenuHandler])

	useEffect(() => {
		setMobileMenu(false)
	}, [location])
	//
	return (
		<div className="app">
			<Header
				empty={!isAuth}
				toggleMobileMenu={() => setMobileMenu(!mobileMenu)}
				icon={mobileMenu}
			/>
			<div className="container">
				{isAuth && location.pathname !== '/form' && (
					<Menu mobileMenu={mobileMenu} menuRef={mobileMenuRef}>
						<ProfileBlock />
						<Nav />
						<NavLink to="/about" className="app-side-menu__link">
							О&nbsp;сообществе <span className="nowrap">X-Cluster</span>
						</NavLink>
					</Menu>
				)}
				<main className="page-container">{children}</main>

				{isAuth && location.pathname !== '/form' && device === 'mobile' && (
					<div className="mobile-menu-bottom">
						<Nav />
					</div>
				)}
			</div>
		</div>
	)
}

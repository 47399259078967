import { createSlice } from '@reduxjs/toolkit'

import newsApi from '../../../api/news-api'

import { addNot } from '../global-nots/global-nots'

const initialState = {
	newsList: [],
	newsById: {},
	filters: {
		type: 0,
		limit: 11,
		offset: 0,
	},
	isLoading: false,
	isLoaded: false,
	failed: false,
	count: null,
}

const news = createSlice({
	name: 'news',
	initialState,
	reducers: {
		setNews: (state, action) => {
			state.newsList = action.payload.newsList
		},
		setNewsById: (state, action) => {
			state.newsById = action.payload.newsById
		},
		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
		setIsLoaded: (state, action) => {
			state.isLoaded = action.payload.isLoaded
		},
		setFailed: (state, action) => {
			state.failed = action.payload.failed
		},
		setNewsCount: (state, action) => {
			state.count = action.payload.count
		},
		setDevice: (state, action) => {
			state.device = action.payload.device
		},
	},
})

const { reducer, actions } = news

export const {
	setNews,
	setNewsById,
	setFilters,
	setIsLoading,
	setIsLoaded,
	setFailed,
	setNewsCount,
	setDevice,
} = actions

// Получение списка новостей
export const requestNews = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		news: { filters, isLoaded, newsList },
	} = getState()

	const res = await newsApi.requestNews(filters)

	if (res.success) {
		dispatch(setNews({ newsList: [...newsList, ...res.result.news] }))
		dispatch(setNewsCount({ count: res.result.count }))

		if (!isLoaded) {
			dispatch(setIsLoaded({ isLoaded: true }))
		}
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

//получение новости по id
export const requestNewsById = (id) => async (dispatch) => {
	const res = await newsApi.requestNewsById(id)

	if (res.success) {
		dispatch(setNewsById({ newsById: res.result }))
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer

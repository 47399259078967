import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { url } from '../../../../../api/api'

import { Slider } from '../../../../../components/Slider/Slider'

import './EventGallery.scss'
import { PlayerModal } from './PlayerModal/PlayerModal'

export const EventGallery = React.memo(({ title, gallery, photo = false, video = false }) => {
	const [full, setFull] = useState(false)
	const [isSliderOpen, setIsSliderOpen] = useState(false)
	const [isPlayerOpen, setIsPlayerOpen] = useState(false)
	const [currentPhoto, setCurrentPhoto] = useState(0)
	const [currentVideo, setCurrentVideo] = useState(0)

	const openSlider = (i) => () => {
		setIsSliderOpen(true)
		setCurrentPhoto(i)
	}

	const closeSlider = () => {
		setIsSliderOpen(false)
		setCurrentPhoto(0)
	}

	const openPlayer = (i) => () => {
		setIsPlayerOpen(true)
		setCurrentVideo(i)
	}

	const closePlayer = () => {
		setIsPlayerOpen(false)
		setCurrentVideo(0)
	}

	return (
		<div className="event-gallery">
			<div className="event-gallery__title">{title}</div>
			<div className="event-gallery__container">
				{photo &&
					(full ? gallery : gallery.slice(0, 4)).map((item, i) => (
						<div
							key={i}
							onClick={openSlider(i)}
							className="event-gallery__item"
							style={{ backgroundImage: `url(${url}/static/${item})` }}
						></div>
					))}

				{video &&
					(full ? gallery : gallery.slice(0, 4)).map((item, i) => (
						<div key={i} className="event-gallery__item">
							<div className="event-gallery__item--overlay" onClick={openPlayer(i)}></div>
							<ReactPlayer
								light
								url={item}
								width="100%"
								height="100%"
								onBuffer={(event) => event.preventDefault()}
							/>
						</div>
					))}
			</div>
			{gallery.length > 4 && (
				<div className="event-gallery__full-button">
					<span onClick={() => setFull(!full)}>{full ? 'Свернуть' : `Все ${title}`}</span>
				</div>
			)}
			{photo && isSliderOpen && (
				<Slider onClose={closeSlider} gallery={gallery} initial={currentPhoto} />
			)}
			{video && isPlayerOpen && (
				<PlayerModal show={isPlayerOpen} onClose={closePlayer} video={gallery[currentVideo]} />
			)}
		</div>
	)
})

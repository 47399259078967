import React from 'react'

import { FilterItem } from '../Filter/FilterItem/FilterItem'

export const FilterSubtitles = React.memo(({ subtitle, skillsList, handler, active, index }) => (
	<div className="filter filter--whith-subtitles" key={index}>
		<div className="filter__subtitle">{subtitle}</div>
		<div className="filter__items">
			{!!skillsList.length &&
				skillsList.map((section, i) => {
					return (
						<div key={`${section[1]}-${i}`}>
							<FilterItem
								title={section[1]}
								filter={section}
								handler={handler(section[0])}
								active={active(section[0])}
							/>
						</div>
					)
				})}
		</div>
	</div>
))

import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'

import { RegistForm } from '../RegistForm/RegistForm'
import { Button } from '../../../components/Buttons/Button/Button'
import { Input } from '../../../components/Input/Input'
import { FormGroup } from '../RegistForm/FormGroup/FormGroup'
import { ActiveText } from '../../../components/ActiveText/ActiveText'
import { FormHeader } from '../RegistForm/FormHeader/FormHeader'
import { MessageTimeout } from '../MessageTimeout/MessageTimeout'

import { refreshPin, verif } from '../../../store/reducers/auth/auth'

import { getPhone, getPresence, getType } from '../../../store/reducers/auth/auth-selectors'

import './SecondStepForm.scss'

const validate = (values) => {
	const { code } = values
	const errors = {}

	if (code.length < 4) {
		errors.code = 'Код не должен быть короче 4 символов'
	}

	return errors
}

export const SecondStepForm = React.memo(() => {
	const dispatch = useDispatch()
	const { search } = useLocation()

	const [msgTimer, setMsgTimer] = useState(false)
	const [changeTimer, setChangeTimer] = useState(false)

	const phone = useSelector(getPhone)
	const type = useSelector(getType)
	const presence = useSelector(getPresence)

	const bySms = type === 'sms'

	const handleRefreshCode = () => {
		setMsgTimer(true)
		dispatch(refreshPin(type))
	}

	const handleChangeType = () => {
		setMsgTimer(false)
		setChangeTimer(true)
		dispatch(refreshPin(bySms ? 'bot' : 'sms'))
	}

	const subText = bySms
		? `Мы отправили СМС с кодом на номер +${phone}`
		: 'Наш бот отправил Вам личное сообщение с кодом'

	return (
		<Formik
			initialValues={{ code: '' }}
			validate={validate}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true)

				const { code } = values

				dispatch(verif(code))
				setSubmitting(false)
			}}
		>
			{({ values, errors, isSubmitting }) => {
				const { code } = values

				return (
					<Form>
						<RegistForm>
							<FormHeader text="Введите код" subText={subText} />
							<FormGroup>
								<Field name="code" label="Код" offAutoComplete autofocus as={Input} />
								<div className="sub-text">
									{msgTimer ? (
										<MessageTimeout text="Повторный запрос возможен" setTimer={setMsgTimer} />
									) : (
										<ActiveText onClick={handleRefreshCode}>Запросить код повторно</ActiveText>
									)}
									{presence &&
										(changeTimer ? (
											<MessageTimeout
												text="Повторное переключение возможно"
												setTimer={setChangeTimer}
											/>
										) : (
											<ActiveText onClick={handleChangeType}>
												{bySms ? 'Получить код от бота' : 'Отправить по СМС'}
											</ActiveText>
										))}
								</div>
							</FormGroup>

							<Button
								nType="submit"
								label={search ? 'Заполнить анкету' : 'Войти'}
								type="md2"
								onClick={() => {}}
								disabled={!code || errors.code || isSubmitting}
							/>
						</RegistForm>
					</Form>
				)
			}}
		</Formik>
	)
})

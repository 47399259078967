import { createSlice } from '@reduxjs/toolkit'

import profileApi from '../../../api/profile-api'

import { setIsLoading } from '../app/app'
import { logout } from '../auth/auth'
import { addNot } from '../global-nots/global-nots'

const initialState = {
	user: {},
	groups: [],
	history: [],
	histLoaded: false,
	histOffset: 0,
	histCount: 0,
	bots: [],
	botKey: null,
	isBotsLoading: false,
}

const profile = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload.user
		},
		setGroups: (state, action) => {
			state.groups = action.payload.groups
		},
		setHistory: (state, action) => {
			state.history = action.payload.history
		},
		setOffset: (state, action) => {
			state.histOffset = action.payload.offset
		},
		setCount: (state, action) => {
			state.histCount = action.payload.count
		},
		setBots: (state, action) => {
			state.bots = action.payload.bots
		},
		setBotKey: (state, action) => {
			state.botKey = action.payload.key
		},
		setHistLoaded: (state, action) => {
			state.histLoaded = action.payload.loaded
		},
		setIsBotsLoading: (state, action) => {
			state.isBotsLoading = action.payload.isBotsLoading
		},
	},
})

const { reducer, actions } = profile

export const {
	setUser,
	setGroups,
	setHistory,
	setOffset,
	setCount,
	setBots,
	setBotKey,
	setHistLoaded,
	setIsBotsLoading,
} = actions

// Получение информации о текущем пользователе
export const getCurrentUser = () => async (dispatch, getState) => {
	const {
		app: { initialized },
	} = getState()

	if (!initialized) {
		dispatch(setIsLoading({ isLoading: true }))
	}

	const res = await profileApi.getCurrentUser()

	if (res.success) {
		dispatch(setUser({ user: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))

		if (res.result.code === 5) {
			dispatch(logout())
		}
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Изменение фотографии пользователя
export const uploadPhoto = (data) => async (dispatch) => {
	const res = await profileApi.uploadPhoto(data)

	if (res.success) {
		dispatch(getCurrentUser())
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение списка групп
export const requestGroups = () => async (dispatch) => {
	const res = await profileApi.requestGroups()

	if (res.success) {
		dispatch(setGroups({ groups: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение истории транзакций
export const requestHistory = () => async (dispatch, getState) => {
	const {
		profile: { history, histOffset: offset, histLoaded: loaded },
	} = getState()

	const res = await profileApi.requestHistory(offset)

	if (res.success) {
		dispatch(setHistory({ history: [...history, ...res.result.trans] }))
		dispatch(setCount({ count: res.result.count }))

		if (!loaded) {
			dispatch(setHistLoaded({ loaded: true }))
		}
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// получение списка ботов
export const requestBots = () => async (dispatch) => {
	dispatch(setIsBotsLoading({ isBotsLoading: true }))

	const res = await profileApi.requestBots()

	if (res.success) {
		dispatch(setBots({ bots: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsBotsLoading({ isBotsLoading: false }))
}

// Получение ключа активации бота
export const requestBotActiveKey = (type) => async (dispatch) => {
	const res = await profileApi.requestBotActiveKey(type)

	if (res.success) {
		dispatch(setBotKey({ key: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Отключение бота
export const disableBot = (type) => async (dispatch) => {
	const res = await profileApi.disableBot(type)

	if (res.success) {
		dispatch(requestBots())
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer

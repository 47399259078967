import React from 'react'
import { useDispatch } from 'react-redux'

import { IntegrationsModalTitle as Title } from '../IntegrationsModalTitle/IntegrationsModalTitle'
import { IntegrationsModalSubtitle as Subtitle } from '../IntegrationsModalSubtitle/IntegrationsModalSubtitle'
import { IntegrationsModalButtons as Buttons } from '../IntegrationsModalButtons/IntegrationsModalButtons'

import { disableBot } from '../../../../../store/reducers/profile/profile'

export const DisintegrationModalContent = React.memo(({ info, onClose }) => {
	const dispatch = useDispatch()

	if (!info) return null

	const { type, title, icon } = info

	const handleSubmit = () => {
		dispatch(disableBot(type))
		onClose()
	}

	return (
		<div className="disintegration-modal-content">
			<Title label={`Отвязать ${title}`} icon={icon} />
			<Subtitle text="Вы уверены, что хотите отменить интеграцию с ботом? Вы перестанете получать уведомления об обновлениях в вашем аккаунте" />
			<Buttons red submitText="Отвязать" onCancelClick={onClose} onSubmitClick={handleSubmit} />
		</div>
	)
})

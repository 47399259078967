import instance from './api'

import { getToken } from '../functions/localStorage'

const commentsApi = {
	async requestComments(filters, target_id) {
		const response = await instance.post(
			`user/get/comment`,
			{ ...filters, target_id },
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async addComment(msg, target_id, target_type) {
		const response = await instance.post(
			`user/create/comment`,
			{ msg, target_id, target_type },
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},
}

export default commentsApi

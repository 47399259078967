import React from 'react'
import cn from 'classnames'

import './IconItem.scss';

export const IconItem = React.memo(({label, classname, position}) => {
	return (
		<div className={cn("profit-second__icon-item-wrapper", classname)}>
			<div className={cn("profit-second__icon-item", position)}>
				<div className="label subtitle">{label}</div>
				<div className="icon-wrapper"><i className={`icon icon-${classname}`}></i></div>
			</div>
		</div>
	)
})
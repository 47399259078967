import { getToken } from '../functions/localStorage'
import instance from './api'

const appApi = {
	async apply(id, type) {
		const response = await instance.post(
			`user/create/props?product_id=${id}&type=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async cancel(id) {
		const response = await instance.post(
			`user/delete/props?product_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async sendContactUsForm(data) {
		const response = await instance.post('support/send/email', data)

		return response.data
	},

	async sendRefsConversion(data) {
		const response = await instance.post(`support/referal`, data)

		return response.data
	},
}

export default appApi

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'

import { Button } from '../../components/Buttons/Button/Button'
import { Input } from '../../components/Input/Input'
import { approveCertificate } from '../../store/reducers/certificates/certificates'
import { getStatus } from '../../store/reducers/certificates/certificates-selectors'

import './AcceptCertificatePage.scss'

export const AcceptCertificatePage = React.memo(() => {
	const dispatch = useDispatch()
	const match = useRouteMatch()
	const hash = match.params.hash

	const status = useSelector(getStatus)

	const [password, passwordHandler] = useState('')

	return (
		<div className="accept-certificate-page">
			<div className="header-block">
				<div className="header-block__icon">
					<Link to="/" />
				</div>
			</div>
			<div className="body-block">
				{status ? (
					<div className="accept-block">
						<i className="activated" />
						<p>Сертификат одобрен</p>
					</div>
				) : (
					<div className="body-block__container">
						<h2 className="body-block__header">Подтверждение сертификата</h2>
						<div className="body-block__accept-block">
							<Input
								label="Введите пароль"
								value={password}
								onChange={({ target: { value } }) => passwordHandler(value)}
							/>
							<Button
								small
								label="Подтвердить"
								onClick={() => dispatch(approveCertificate(hash, password))}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	)
})

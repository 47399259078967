import React from 'react'
import { useSelector } from 'react-redux'
import { Link, NavLink, useLocation } from 'react-router-dom'
import cn from 'classnames'

import { Notifications } from './Notifications/Notifications'
import { Avatar } from '../Avatar/Avatar'
import { HeaderStatus as Status } from './HeaderStatus/HeaderStatus'
import { Certificates } from './Certificates/Certificates'
import { PointsCounter } from '../PointsCounter/PointsCounter'

import {
	getPhoto,
	getMoney,
	getIsModerating,
	getIsRejected,
} from '../../store/reducers/profile/profile-selectors'
import { getDevice } from '../../store/reducers/app/app-selectors'
import { getIsAuth } from '../../store/reducers/auth/auth-selectors'

import logo from '../../assets/media/svg/mobile-menu/mobile-menu-logo.svg'

import './Header.scss'

export const Header = ({ empty, toggleMobileMenu, icon }) => {
	const location = useLocation()

	const isModerating = useSelector(getIsModerating)
	const isRejected = useSelector(getIsRejected)

	const isAuth = useSelector(getIsAuth)
	const money = useSelector(getMoney)
	const photo = useSelector(getPhoto)
	const device = useSelector(getDevice)

	const checkLink = () => {
		if (!location) return false
		const { pathname } = location

		if (pathname !== '/work' && pathname !== '/campus') {
			return true
		}
	}

	return (
		<header className={cn('app-header', { active: icon })}>
			{isAuth && location.pathname !== '/form' && (
				<div
					className={cn('mobile-menu-button', { active: icon })}
					onClick={toggleMobileMenu}
				>
					<i></i>
				</div>
			)}

			{isAuth && (
				<nav className={cn({ 'mobile-header-logo': device === 'mobile' })}>
					<NavLink exact to="/" className="nav-link" isActive={checkLink}>
						<div className="x-icon x-name cluster"></div>
					</NavLink>
					<NavLink to="/work" className="nav-link">
						<div className="x-icon x-name work"></div>
					</NavLink>
					<NavLink to="/campus" className="nav-link">
						<div className="x-icon x-name campus"></div>
					</NavLink>
				</nav>
			)}

			{!isAuth && (
				<Link to="/">
					<img src={logo} alt="Logo" />
				</Link>
			)}

			{!empty && (
				<div className="info-container">
					{(isRejected || isModerating) && (
						<Status waiting={isModerating} fail={isRejected} />
					)}

					{device === 'desktop' && <Certificates />}

					{device !== 'desktop' && device !== 'mobile' && (
						<PointsCounter count={money} />
					)}

					<Notifications />

					<Avatar small inHeader image={photo} />
				</div>
			)}
		</header>
	)
}

import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import { EventGallery } from './EventGallery/EventGallery'
import { EventButtons } from '../../EventButtons/EventButtons'
import { Button } from '../../../../components/Buttons/Button/Button'
import { Comments } from '../../../../components/Comments/Comments'
import { PurifyHtml } from '../../../../components/PurifyHtml/PurifyHtml'

import { url } from '../../../../api/api'

import {
	applyEvent,
	approveCertificate,
	cancelEvent,
} from '../../../../store/reducers/events/events'
import { addNot } from '../../../../store/reducers/global-nots/global-nots'

import { getEventCertificate } from '../../../../store/reducers/events/events-selectors'
import { getDevice } from '../../../../store/reducers/app/app-selectors'
import { getIsAuth } from '../../../../store/reducers/auth/auth-selectors'

import './Event.scss'

import { createDate, createDateRange } from '../../../../functions/common'

export const Event = React.memo(({ event, past }) => {
	const history = useHistory()
	const dispatch = useDispatch()

	const isAuth = useSelector(getIsAuth)
	const device = useSelector(getDevice)

	const cert = useSelector(getEventCertificate)

	const {
		id,
		image,
		count,
		name,
		loc_addr: address,
		time_start: start,
		time_end: end,
		status_props: status,
		status_msg: statusMsg,
		props_id: propsId,
		description,
		photo,
		video,
		is_planned: isPlanned,
		is_stream: isStream,
		stream_link: streamLink,
		stream_start: streamStart,
		stream_end: streamEnd,
	} = event

	const certStatus = cert?.status

	const approved = status === 2

	const dateOptions = {
		month: 'long',
		day: 'numeric',
	}

	const timeOptions = {
		hour: 'numeric',
		minute: 'numeric',
	}

	const handleStreamClick = () => {
		const isStreamEnded = new Date(streamEnd) < new Date()

		if (isStreamEnded) {
			dispatch(
				addNot({
					not: 'Трансляция завершена',
					type: 'info',
				})
			)

			return
		}

		if (!streamLink) {
			dispatch(
				addNot({
					not: `Трансляция начнется ${createDate(
						streamStart,
						'ru',
						dateOptions
					)} в ${createDate(
						streamStart,
						'ru',
						timeOptions
					)} и будет доступна за 15 минут до начала события`,
					type: 'info',
				})
			)
		} else {
			if (certStatus === 1) {
				dispatch(approveCertificate(propsId))
			}
			window.open(streamLink, '_blank')
		}
	}

	const handleCheckinClick = () => {
		history.push('/checkin')
	}

	return (
		<div className="event">
			<Helmet>
				<meta property="og:title" content={`${event.name}`} />
				<meta name="og:description" content={`${event.description}`} />
				<meta property="og:image" content={`${event.image}`}></meta>
			</Helmet>

			{image && (
				<div
					className="event__image"
					style={{ backgroundImage: `url(${url}/static/${image})` }}
				>
					<div className="go-back-button" onClick={() => history.goBack()} />
					{!!count && !past && (
						<div className="event__places">
							<span>Осталось мест </span>
							<span>{count}</span>
						</div>
					)}
				</div>
			)}

			{isAuth && device === 'mobile' && (
				<Link to={'/events/'} className="arrow-link go-back">
					<i></i>
					События
				</Link>
			)}

			<div className="event__title">{name}</div>
			<div className="event__info">
				<span>{address ? address : 'Онлайн'}</span>

				{!isPlanned && (
					<>
						<span> · </span>
						<span>
							{start === end
								? createDate(start, 'ru', dateOptions)
								: createDateRange(start, end, 'ru', dateOptions)}
						</span>
					</>
				)}

				{!!count && (
					<div className="event__places">
						<span>Осталось мест </span>
						<span>{count}</span>
					</div>
				)}
			</div>

			{isAuth && device !== 'mobile' && (
				<EventButtons
					cert={cert}
					past={past}
					status={status}
					count={count}
					id={id}
					cancelEvent={cancelEvent}
					applyEvent={applyEvent}
					statusMsg={statusMsg}
				/>
			)}

			<div className="event__description">
				<PurifyHtml content={description} />
			</div>

			{isAuth && approved && isStream && (
				<div className="event__stream-button">
					<Button label="Перейти на трансляцию" onClick={handleStreamClick} />
				</div>
			)}

			{isAuth && photo && !!photo.length && (
				<EventGallery photo gallery={photo} title="Фото" />
			)}

			{isAuth && video && !!video.length && (
				<EventGallery video gallery={video} title="Видео" />
			)}

			{isAuth && past && device !== 'mobile' && (
				<Comments approved={approved} certStatus={certStatus} />
			)}

			{!isAuth && (
				<div className="event__not-auth">
					<div className="not-auth__text">
						Чтобы принять участие в забытии необходимо авторизоваться или
						зарегистрироваться на платформе
					</div>

					<div className="event__checkin-button">
						<Button label="Зарегистрироваться" onClick={handleCheckinClick} />
					</div>

					<div className="event__login-link">
						<span>или </span>
						<Link to="/login">Войти</Link>
					</div>
				</div>
			)}
		</div>
	)
})

import React, { useState } from 'react'

import { ProgrammTabs } from './ProgrammTabs/ProgrammTabs'
import { ProgrammTabContent } from './ProgrammTabContent/ProgrammTabContent'
import { ProgrammTabContentItem } from './ProgrammTabContent/ProgrammTabContentItem/ProgrammTabContentItem'

import './Programm.scss'

import { createDate } from '../../../../functions/common.js'

export const Programm = React.memo(({ programm }) => {
	const dateOptions = {
		weekday: 'short',
		day: 'numeric',
		month: 'short',
	}

	const timeOptions = {
		hour: 'numeric',
		minute: 'numeric',
	}

	const tabs = Object.keys(programm).map((date) => createDate(date, 'ru', dateOptions))
	const tabsContent = Object.values(programm)

	const [activeTabs, setActiveTabs] = useState([true, ...Array(tabs.length - 1).fill(false)])

	return (
		<div className="event-programm">
			<div className="event-programm__title">Программа</div>
			<ProgrammTabs tabs={tabs} active={activeTabs} setActive={setActiveTabs} />
			{tabs.map((tab, index) => (
				<ProgrammTabContent key={`${tab}-${index}`} visible={activeTabs[index]}>
					{tabsContent[index].map((item, i) => (
						<ProgrammTabContentItem
							key={`${item.name}-${i}`}
							time={`${createDate(item.time_start, 'ru', timeOptions)} – ${createDate(
								item.time_end,
								'ru',
								timeOptions
							)}`}
							title={item.name}
							desc={item.description}
							speaker={item.lecturer}
						/>
					))}
				</ProgrammTabContent>
			))}
		</div>
	)
})

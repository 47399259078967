import React from 'react'

import { JoinUs } from './JoinUs/JoinUs'
import { GeneralTerms } from './GeneralTerms/GeneralTerms'
import { ButtonsBlock } from './ButtonsBlock/ButtonsBlock'

import './Main.scss'

export const Main = React.memo(() => {
	return (
		<div className="main-section">
			<JoinUs />
			<GeneralTerms />
			<ButtonsBlock />
		</div>
	)
})
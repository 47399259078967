import React from 'react'

import { SocialIconsItem } from './SocialIconsItem/SocialIconsItem'

import './SocialIcons.scss'

import vk from '../../../../../../assets/media/landing/icons/social/vk.svg'
import fb from '../../../../../../assets/media/landing/icons/social/fb.svg'
import inst from '../../../../../../assets/media/landing/icons/social/inst.svg'
import yt from '../../../../../../assets/media/landing/icons/social/yt.svg'

export const SocialIcons = React.memo(() => {
	return (
		<div className="join-us__icons-block">
			<SocialIconsItem icon={fb} link="https://www.facebook.com/xcluster39" />
			<SocialIconsItem icon={yt} link="https://www.youtube.com/channel/UCEBpTvuN7gV9dgJVyfd6cqQ" />
			<SocialIconsItem icon={inst} link="https://www.instagram.com/x__cluster/" />
			<SocialIconsItem icon={vk} link="https://vk.com/x_cluster" />
		</div>
	)
})

import instance from './api'

import { getToken } from '../functions/localStorage'

const officesApi = {
    async requestOfficesList(filters) {
        const responce = await instance.post(
            'user/search/office',
            {
                ...filters
            },
            {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
            }
        )

        return responce.data
    },

    async requestOfficeById(id, date) {
        const responce = await instance.post(
            `office/get?office_id=${id}&date=${date}`,
            {},
            {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
            }
        )

        return responce.data
    },

    async requestOfficeCalendar(id, date) {
        const responce = await instance.post(
            `user/get/officecal?office_id=${id}&date=${date}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            }
        )

        return responce.data
    },

    async requestOfficeRequest(id, startDay, endDay) {
        const responce = await instance.post(
            `/user/create/props?product_id=${id}&type=1&time_start=${startDay}&time_end=${endDay}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            }
        )

            return responce.data
    },

    async rejectOfficeRequest(id) {
        const responce = await instance.post(
            `user/delete/props?product_id=${id}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }, 
            }
        )

        return responce.data
    }
}

export default officesApi
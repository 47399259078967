import { createSlice } from '@reduxjs/toolkit'

import knowledgeBaseApi from '../../../api/knowledge-base-api'

import { addNot } from '../global-nots/global-nots'

const initialState = {
	knowledgeBase: [],
	articleInfo: {},
	count: 0,
	filters: {
		type: 0,
		limit: 11,
		offset: 0,
		tag: '',
	},
	failed: false,
	isLoading: false,
	isLoaded: true,
	searched: false,
}

const knowledgeBase = createSlice({
	name: 'knowledge-base',
	initialState,
	reducers: {
		setKnowledgeBase: (state, action) => {
			state.knowledgeBase = action.payload.base
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setArticleInfo: (state, action) => {
			state.articleInfo = action.payload.info
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setFailed: (state, action) => {
			state.failed = action.payload.failed
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setIsLoaded: (state, action) => {
			state.isLoaded = action.payload.isLoaded
		},

		setSearched: (state, action) => {
			state.searched = action.payload.searched
		},
	},
})

const { reducer, actions } = knowledgeBase

export const {
	setKnowledgeBase,
	setCount,
	setArticleInfo,
	setFilters,
	setFailed,
	setIsLoading,
	setIsLoaded,
	setSearched,
} = actions

// Получение базы знаний
export const requestKnowledgeBase = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		knowledgeBase: { knowledgeBase, filters, isLoaded, searched },
	} = getState()

	const { tag } = filters

	const res = await knowledgeBaseApi.requestKnowledgeBase(filters)

	if (res.success) {
		if (tag) {
			if (!searched) dispatch(setSearched({ searched: true }))
			dispatch(setKnowledgeBase({ base: res.result.knows }))
		} else {
			if (!searched) {
				dispatch(
					setKnowledgeBase({ base: [...knowledgeBase, ...res.result.knows] })
				)
			} else {
				dispatch(setSearched({ searched: false }))
				dispatch(setKnowledgeBase({ base: res.result.knows }))
			}
		}

		dispatch(setCount({ count: res.result.count }))

		if (!isLoaded) {
			dispatch(setIsLoaded({ isLoaded: true }))
		}
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

//получение статьи по id
export const requestArticleById = (id) => async (dispatch) => {
	const res = await knowledgeBaseApi.requesArticleById(id)

	if (res.success) {
		dispatch(setArticleInfo({ info: res.result }))
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer

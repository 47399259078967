import React from 'react'

import './SocialIconsItem.scss'

export const SocialIconsItem = React.memo(({ icon, link }) => {
	return (
		<a href={link} className="join-us__icons-block-item" target="_blank" rel="noopener noreferrer">
			<i style={{ backgroundImage: `url(${icon})` }}></i>
		</a>
	)
})

import React from 'react'
import cn from 'classnames'

import { IconButton } from '../IconButton/IconButton'

import './ModalContainer.scss'

export const ModalContainer = ({ show = false, onClose, certificate, children, style }) => {
	if (show) {
		document.body.style.overflow = 'hidden'
	} else {
		document.body.style.overflow = null
	}

	return (
		<div
			className={cn('modal-wrapper', { show, certificate })}
			onMouseDown={({ target }) => {
				if (!target.closest('.modal-container')) onClose()
			}}
		>
			<div className="modal-container" style={style}>
				<IconButton className="btn-close" iconName="close" size={32} onClick={onClose} />
				{children}
			</div>
		</div>
	)
}

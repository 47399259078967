import React from 'react'

import './PanelTitle.scss'

export const PanelTitle = React.memo(({ text, style, children }) => (
	<div className="panel-title" style={style}>
		<h3 className="panel-title__text">{text}</h3>
		<div className="panel-title__children">{children}</div>
	</div>
))

import React from 'react'
import cn from 'classnames'

import { FilterItem } from './FilterItem/FilterItem'
import { FilterRange } from './FilterRange/FilterRange'
import { CheckBox } from './CheckBox/CheckBox'

import './Filter.scss'

export const Filter = React.memo(
	({ title, items = [], range, value, setValue, maxValue, style, checkbox, active, handler }) => (
		<div className={cn('filter', { hide: style })}>
			<div className="filter__title">{title}</div>
			<div className="filter__items">
				{items.map((item, i) =>
					range ? (
						<FilterRange
							key={`${item.title}-${i}`}
							text={item}
							value={value}
							setValue={setValue}
							maxValue={maxValue}
						/>
					) : checkbox ? (
						<CheckBox
							active={active}
							key={`${item[0]}-${i}`}
							index={i}
							item={item}
							handler={handler}
						/>
					) : (
						<FilterItem key={`${item.title}-${i}`} {...item} filter={item} />
					)
				)}
			</div>
		</div>
	)
)

import React from 'react'
import ReactPlayer from 'react-player'
import cn from 'classnames'

import './PlayerModal.scss'

export const PlayerModal = React.memo(({ video, show, onClose }) => {
	const handleClose = ({ target }) => {
		if (!target.closest('.player-modal__container')) onClose()
	}

	return (
		<div className={cn('player-modal', { show })} onClick={handleClose}>
			<div className="player-modal__container">
				<ReactPlayer url={video} controls />
			</div>
		</div>
	)
})

import React from 'react'

import './ContactsFooter.scss'

import logo from '../../../../../assets/media/landing/logo_color.png'

export const ContactsFooter = React.memo(() => {
	return (
		<div className="contacts-section__footer">
			<div className="contacts-section__footer-logo-block">
				<div className="contacts-section__footer-logo">
					<img className="header-logo" src={logo} alt="" />
				</div>
				<p className="contacts-section__footer-logo-text">
					Автономная некоммерческая организация развития информационных технологий <br />
					«Икс-Кластер» (АНО «Икс-Кластер»)
				</p>
				<div className="contacts-section__footer-copyrigth adaptive">
					<p>Copyright © 2020 «X-Cluster» Все права защищены.</p>
				</div>
			</div>
			<div className="contacts-section__footer-copyrigth">
				<p>Copyright © 2020 «X-Cluster» Все права защищены.</p>
			</div>
			<div className="contacts-section__footer-legal-address">
				<p>ОГРН: 1203900012029</p>
				<p>ИНН: 3906394960</p>
				<p>КПП: 390601001</p>
				<p>Юридический адрес:</p>
				<p>
					236022, Калининградская область, город Калининград, улица Генерал-лейтенанта Озерова, дом
					17б литер VIII , этаж 8, офис 823
				</p>
			</div>
		</div>
	)
})

import React from 'react'
import cn from 'classnames'

import './Button.scss'

export const Button = ({
	className,
	label,
	nType = 'button',
	type = 'lg', // md1, md2, lg,
	white,
	red,
	green,
	arrow,
	disabled,
	uppercase,
	onClick,
}) => (
	<button
		type={nType}
		className={cn(
			'button-main',
			type,
			className,
			{ white, red, green, uppercase }
			// { arrow },
		)}
		onClick={onClick}
		disabled={disabled}
	>
		<span className="bg"></span>
		<span className="label">{label}</span>
		{arrow && <span className="arrow"></span>}
	</button>
)

import React from 'react'

import { url } from '../../../../../api/api'

import './IntegrationsModalTitle.scss'

export const IntegrationsModalTitle = React.memo(({ label, icon }) => (
	<div className="integrations-modal-title">
		{label}
		<div className="title-icon">
			<i style={{ backgroundImage: `url(${url}/static/${icon})` }}></i>
		</div>
	</div>
))

import React, { useState } from 'react'
import cn from 'classnames'

import './FaqItem.scss'

export const FaqItem = React.memo(({ question, children }) => {
	const [show, setShow] = useState(false)

	return (
		<button
			onClick={() => setShow(!show)}
			className={cn('landing-faq__item', { show })}
			type="button"
		>
			<div className={cn('landing-faq__item-button', { show })}></div>
			<div className="landing-faq__item-question-wrapper">
				<div className="landing-faq__item-question">{question}</div>
				<div className={cn('landing-faq__item-answer', { show })}>{children}</div>
			</div>
		</button>
	)
})

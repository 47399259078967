import instance from './api'

import { getToken } from '../functions/localStorage'

const partnersApi = {
	async getInfo() {
		const response = await instance.post(
			'recrut/get/info',
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async requestPartners(filters) {
		const response = await instance.post(
			'recrut/safesearch',
			{ ...filters },
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async requestPointsHistory(filters) {
		const response = await instance.post(
			'recrut/get/points',
			{ ...filters },
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},
}

export default partnersApi

import React from 'react'
import cn from 'classnames'

import { CertificatesModalContentDate } from '../CertificatesModalContentDate/CertificatesModalContentDate'

import './CertificateContent.scss'

import { createDate, createDateRange } from '../../../functions/common'

export const CertificateContent = React.memo(
	React.forwardRef(({ certificate }, ref) => {
		const {
			addr,
			addr_desc,
			code,
			name,
			qr,
			is_delivery: isDelivery,
			type,
			time_start: start,
			time_end: end,
			status,
			status_msg: statusMsg,
		} = certificate
		const office = type === 1
		const event = type === 2
		const product = type === 3

		const used = status === 2
		const rejected = status === 3 || status === 4

		const dateOptions = {
			weekday: 'short',
			day: 'numeric',
			month: 'short',
		}

		return (
			<div ref={ref} className={cn('certificate-modal-content', { rejected })}>
				<div className="certificate-modal-content__title">{name}</div>
				<div className="certificate-modal-content__dates">
					{event && <span>Даты проведения:</span>}
					{office && <span>Даты бронирования:</span>}
					{product && <span>Действует до</span>}
					<CertificatesModalContentDate
						date={
							start === end
								? createDate(start, 'ru', dateOptions)
								: createDateRange(start, end, 'ru', dateOptions)
						}
					/>
				</div>
				<div className="certificate-modal-content__address">
					{event && addr && (
						<p>
							Адрес проведения: <span>{addr && `${addr}. ${addr_desc}`}</span>
						</p>
					)}
					{office && addr && (
						<p>
							Адрес офиса: <span>{addr && `${addr}. ${addr_desc}`}</span>
						</p>
					)}
					{product && addr && (
						<p>
							Место получения: <span>{addr && `${addr}. ${addr_desc}`}</span>
						</p>
					)}
				</div>
				{product && (
					<div className="certificate-modal-content__conditions">
						<p>Условие получения</p>
						{isDelivery ? <p>Доставка</p> : <p>Самовывоз</p>}
					</div>
				)}
				{!used && !rejected && (
					<>
						<div className="certificate-modal-content__qr-code">
							{qr && (
								<div
									className="qr-code"
									style={{
										backgroundImage: `url(${qr})`,
									}}
								></div>
							)}
						</div>
						<div className="certificate-modal-content__code">
							<span>код</span>
							<span>{code}</span>
						</div>
					</>
				)}
				{used && (
					<div className="certificate-modal-content__used">
						<i></i>Сертификат использован
					</div>
				)}
				{rejected && (
					<div className="certificate-modal-content__rejected">
						<div className="rejected-status">
							<i></i>
							Сертификат отменен
						</div>
						<div className="rejected-reason">{statusMsg}</div>
					</div>
				)}
			</div>
		)
	})
)

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ColumnsContainer } from '../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { RightColumn } from '../../../components/PageWrapper/ColumnsContainer/RightColumn/RightColumn'
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { Event } from './Event/Event'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { EventMap } from './EventMap/EventMap'
import { Programm } from './Programm/Programm'
import { BreadCrumbs } from '../../../components/BreadCrumbs/BreadCrumbs'
import { Preloader } from '../../../components/Preloader/Preloader'
import { EventButtons } from '../EventButtons/EventButtons'
import { Comments } from '../../../components/Comments/Comments'

import {
	setCertificate,
	setEventInfo,
	setFailed,
	applyEvent,
	cancelEvent,
} from '../../../store/reducers/events/events'
import { setType } from '../../../store/reducers/comments/comments'

import { getEventCertificate } from '../../../store/reducers/events/events-selectors'
import { getDevice } from '../../../store/reducers/app/app-selectors'
import { getIsAuth } from '../../../store/reducers/auth/auth-selectors'

import './EventPage.scss'

export const EventPage = React.memo(({ event, failed }) => {
	const dispatch = useDispatch()
	const history = useHistory()

	const isAuth = useSelector(getIsAuth)
	const cert = useSelector(getEventCertificate)
	const device = useSelector(getDevice)

	useEffect(() => {
		if (failed) {
			history.push(isAuth ? '/events' : '/')
		}
	}, [history, failed, isAuth])

	useEffect(() => {
		dispatch(setType({ type: 2 }))
	}, [dispatch])

	useEffect(() => {
		return () => {
			dispatch(setEventInfo({ event: {} }))
			dispatch(setCertificate({ certificate: null }))
			dispatch(setFailed({ failed: false }))
		}
	}, [dispatch])

	const crumbs = [{ link: '/events', text: 'События' }, { text: event.name }]

	const past = event.status === 3

	return (
		<div className="event-page">
			<PageWrapper>
				{!Object.keys(event).length && !failed ? (
					<Preloader full />
				) : (
					<>
						{isAuth && <BreadCrumbs items={crumbs} />}
						<ColumnsContainer>
							<LeftColumn>
								<Event event={event} past={past} />
							</LeftColumn>
							<RightColumn>
								<EventMap
									x={event.loc_x}
									y={event.loc_y}
									address={event.loc_addr}
									comment={event.loc_desc}
									past={past}
								/>
								{event.programms && !event.is_planned && (
									<Panel>
										<Programm programm={event.programms} />
										{device === 'mobile' && (
											<EventButtons
												cert={cert}
												past={past}
												status={event.status_props}
												count={event.count}
												id={event.id}
												cancelEvent={cancelEvent}
												applyEvent={applyEvent}
												statusMsg={event.status_msg}
											/>
										)}
									</Panel>
								)}

								{isAuth && past && device === 'mobile' && (
									<Comments
										approved={event.status_props === 2}
										certStatus={cert?.status}
									/>
								)}
							</RightColumn>
						</ColumnsContainer>
					</>
				)}
			</PageWrapper>
		</div>
	)
})

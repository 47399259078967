import React from 'react'

import { IntegrationsItem as Item } from './IntegrationsItem/IntegrationsItem'

import './IntegrationsItems.scss'

export const IntegrationsItems = React.memo(({ bots }) => (
	<div className="integrations-items">
		{bots.map((bot, i) => (
			<Item key={`${bot.title}-${i}`} bot={bot} />
		))}
	</div>
))

import React from 'react'
import cn from 'classnames'

import './LightButton.scss'

export const LightButton = ({
	label,
	red = false,
	onClick = () => {},
	className = '',
	image,
}) => (
	<button className={cn(`light-button ${className}`, { red })} onClick={onClick}>
		{image && <i style={{ backgroundImage: `url(${image})` }}></i>}
		{label}
	</button>
)

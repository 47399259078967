import React, { useCallback, useEffect, useRef } from 'react'
import cn from 'classnames'

import './Panel.scss'

export const Panel = React.memo(({ disabled, children, style, right, openFunc, openTrigger }) => {
	const panelRef = useRef()

	const rightPanelHandler = useCallback(
		(e) => {
			if (
				(panelRef.current && panelRef.current.contains(e.target)) ||
				e.target.closest('.calendar-image') ||
				e.target.closest('.filter-image')
			) {
				return
			}

			openFunc(false)
			document.removeEventListener('mousedown', rightPanelHandler)
		},
		[panelRef, openFunc]
	)

	useEffect(() => {
		if (openTrigger) {
			document.addEventListener('mousedown', rightPanelHandler)
		} else {
			document.removeEventListener('mousedown', rightPanelHandler)
		}
	}, [openTrigger, rightPanelHandler])

	return (
		<>
			<div
				className={cn('panel', { disabled, right, open: openTrigger })}
				style={style}
				ref={panelRef}
			>
				{children}
			</div>
			{/* {openTrigger && <div className="panel-block-main-window" />} */}
		</>
	)
})

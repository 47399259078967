export const getBeforeEvents = ({ events: { beforeEvents } }) => beforeEvents

export const getNowEvents = ({ events: { nowEvents } }) => nowEvents

export const getAfterEvents = ({ events: { afterEvents } }) => afterEvents

export const getFilters = ({ events: { filters } }) => filters

export const getCalendarEvents = ({ events: { calendarEvents } }) =>
	calendarEvents

export const getEventInfo = ({ events: { eventInfo } }) => eventInfo

export const getEventCertificate = ({ events: { certificate } }) => certificate

export const getCities = ({ events: { cities } }) => cities

export const getIsLoading = ({ events: { isLoading } }) => isLoading

export const getIsLoaded = ({ events: { isLoaded } }) => isLoaded

export const getFailed = ({ events: { failed } }) => failed

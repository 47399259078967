import React, { useContext, useState } from 'react'

import { LightButton } from '../../../../../components/Buttons/LightButton/LightButton'

import { ProfileContext } from '../../../ProfileContext'

import { url } from '../../../../../api/api'

import './IntegrationsItem.scss'

export const IntegrationsItem = React.memo(({ bot }) => {
	const { title, is_use: isUse, icon, type } = bot

	const [hover, setHover] = useState(false)

	const { openIntegrationModal, openDisintegrationModal } = useContext(
		ProfileContext
	)

	const handleHover = (hover) => () => {
		setHover(hover)
	}

	return (
		<>
			<div className="integrations-item integrations-items__item">
				<div className="integrations-item__text">
					<div className="integrations-item__icon">
						<i style={{ backgroundImage: `url(${url}/static/${icon})` }}></i>
					</div>
					<div className="integrations-item__label">{title}</div>
				</div>
				<div className="integrations-item__button">
					{isUse ? (
						<div
							onMouseLeave={handleHover(false)}
							onMouseEnter={handleHover(true)}
						>
							{hover ? (
								<LightButton
									red
									label="Отменить"
									onClick={openDisintegrationModal(type)}
								/>
							) : (
								<div className="integrations-item__button--complete">
									Интегрировано
								</div>
							)}
						</div>
					) : (
						<LightButton
							label="Интегрировать"
							onClick={openIntegrationModal(type)}
						/>
					)}
				</div>
			</div>
		</>
	)
})

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import cn from 'classnames'

import { EventPage } from './EventPage/EventPage'
import { EventsList } from './EventsList/EventsList'
import { ModalContainer } from '../../components/ModalContainer/ModalContainer'
import { CertificatesModalContent } from '../../components/CertificatesModalContent/CertificatesModalContent'
import { Preloader } from '../../components/Preloader/Preloader'

import {
	getFilters,
	getEventCertificate,
	getEventInfo,
	getFailed,
	getIsLoading,
	getIsLoaded,
} from '../../store/reducers/events/events-selectors'
import { getIsAuth } from '../../store/reducers/auth/auth-selectors'

import {
	getCitiesForFilters,
	requestEventById,
	requestEventByIdWithoutAuth,
	requestEvents,
	setFilters,
	setIsLoaded,
} from '../../store/reducers/events/events'

import EventsContext from './EventsContext'

import './EventsPage.scss'

const EventsPage = () => {
	const dispatch = useDispatch()
	const match = useRouteMatch()

	const id = match.params.id

	const filters = useSelector(getFilters)

	const isAuth = useSelector(getIsAuth)
	const event = useSelector(getEventInfo)
	const cert = useSelector(getEventCertificate)
	const failed = useSelector(getFailed)
	const isLoading = useSelector(getIsLoading)
	const isLoaded = useSelector(getIsLoaded)

	const [certificateModal, setSertificateModal] = useState(false)

	const handleSertificateModalToggle = (state) => () => {
		setSertificateModal(state)
	}

	useEffect(() => {
		if (isAuth) dispatch(requestEvents())
	}, [dispatch, filters, isAuth])

	useEffect(() => {
		if (id) {
			if (isAuth) dispatch(requestEventById(id))
			else dispatch(requestEventByIdWithoutAuth(id))
		}
	}, [dispatch, id, isAuth])

	useEffect(() => {
		if (isAuth) dispatch(getCitiesForFilters())
	}, [dispatch, isAuth])

	useEffect(
		() => () => {
			dispatch(
				setFilters({
					time: new Date(),
					date_type: 'month',
					type: 0,
					city: '',
					is_proposal: 0,
				})
			)
			dispatch(setIsLoaded({ isLoaded: false }))
		},
		[dispatch]
	)

	return (
		<>
			<div
				className={cn('events-page', {
					list: !id,
					loading: isLoading && !isLoaded,
				})}
			>
				<EventsContext.Provider
					value={{ openCertificateModal: handleSertificateModalToggle(true) }}
				>
					{id ? (
						<EventPage event={event} failed={failed} />
					) : isLoading && !isLoaded ? (
						<Preloader full />
					) : (
						<EventsList />
					)}
				</EventsContext.Provider>
			</div>
			<ModalContainer
				show={certificateModal}
				onClose={handleSertificateModalToggle(false)}
				certificate
			>
				<CertificatesModalContent certificate={cert} />
			</ModalContainer>
		</>
	)
}

export default EventsPage

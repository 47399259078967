import React from 'react'

import './Partner.scss'

import partnerBg from '../../../../assets/media/landing/bg_partners.png'
import tgIcon from '../../../../assets/media/landing/icons/social/tg.svg'

export const Partner = React.memo(() => {
	return (
		<div className="partner-section">
			<div className="partner-section__img-wrapper">
				<img src={partnerBg} alt="partner-puzzle" className="partner-section__img" />
			</div>
			<div className="partner-section__info-wrapper">
				<div className="partner-section__info-title">Как стать партнером X-Сluster ?</div>
				<div className="partner-section__info-text">
					Наше сообщество активно развивается и открыто к новым предложениям. Вы можете стать
					партнером IT-сообщества X-Cluster, для этого напишите нам.
				</div>
				<a
					href="https://t.me/xcluster_admin"
					className="button white"
					target="_blank"
					rel="noopener noreferrer"
				>
					Телеграм
					<i style={{ backgroundImage: `url(${tgIcon})` }}></i>
				</a>
			</div>
		</div>
	)
})

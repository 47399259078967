export const generateID = (prefix = '') => `${prefix}${Math.random().toString(36).substring(7)}`

export const getSocial = (link) => {
	if (link.includes('t.me')) return 'social_tg'
	if (link.includes('habr.com')) return 'social_habr'
	if (link.includes('github.com')) return 'social_github'
	if (link.includes('instagram.com')) return 'social_inst'
	if (link.includes('vk.com')) return 'social_vk'
	if (link.includes('facebook.com') || link.includes('fb.com')) return 'social_fb'
	if (link.includes('linkedin.com') || link.includes('linked.in')) return 'social_in'
	if (link.includes('behance.net')) return 'social_behance'
	if (link.includes('dribbble.com')) return 'social_dribbble'

	// if (link.includes('youtube.com') || link.includes('youtu.be')) return 'social_yt'

	return ''
}

// Генерирует и форматирует дату из ISO-строки
export const createDate = (iso, locale = 'ru', options = {}) =>
	new Date(iso || new Date()).toLocaleString(locale, options)

// Генерирует и форматирует диапазон дат из ISO-строки
export const createDateRange = (from, to, locale, options) =>
	`${createDate(from, locale, options)} - ${createDate(to, locale, options)}`

export const getSocialTitle = (type) => {
	const switchObj = {
		telegram: 'Telegram',
		habr: 'Habr',
		github: 'GitHub',
		instagram: 'Instagram',
		vk: 'VK',
		facebook: 'Facebook',
		linkedin: 'LinkedIn',
		behance: 'Behance',
		dribble: 'Dribbble',
	}

	return switchObj[type]
}

export const getStatusByNumber = (statusNumber) => {
	const switchObj = {
		1: 'Модерация',
		2: 'Активный',
		3: 'Отклонён',
		4: 'Без анкеты',
	}

	if (!!switchObj[statusNumber]) return switchObj[statusNumber]

	return 'Нет статуса'
}

export const getPlugNumber = (id) => {
	return (id.charCodeAt(id.length - 1) + id.charCodeAt(id.length - 2)) % 10
}

export const getSkillLevel = (value) => {
	const defaultObj = { text: '', className: '' }

	const switchObj = {
		1: { text: 'Junior', className: 'junior' },
		2: { text: 'Middle', className: 'middle' },
		3: { text: 'Senior', className: 'senior' },
	}

	if (!!switchObj[value]) return switchObj[value]

	return defaultObj
}

export const getLangLevel = (number) => {
	const switchObj = {
		0: 'A1',
		1: 'A2',
		2: 'B1',
		3: 'B2',
		4: 'C1',
		5: 'C2',
	}

	if (!!switchObj[number]) return switchObj[number]

	return ''
}

export const getLangName = (level) => {
	switch (level) {
		case 0:
			return 'A1'

		case 1:
			return 'A2'

		case 2:
			return 'B1'

		case 3:
			return 'B2'

		case 4:
			return 'C1'

		case 5:
			return ' C2'

		default:
			return 'Неизвестно'
	}
}

export const phoneSpaces = (tel = '') =>
	`${tel[0]} ${tel.substring(1, 4)} ${tel.substring(4, 7)} ${tel.substring(7, 9)} ${tel.substring(
		9,
		11
	)}`

export const workType = (type) => {
	const switchObj = {
		0: 'Офис',
		1: 'Удаленно',
		2: 'Офис, Удаленно',
	}

	return switchObj[type]
}

import React from 'react'

import { PanelSubtitle } from '../../../components/Panels/Panel/PanelSubtitle/PanelSubtitle'
import { PanelTitle } from '../../../components/Panels/Panel/PanelTitle/PanelTitle'
import { IntegrationsItems } from './IntegrationsItems/IntegrationsItems'

import {
	integrationsSubtitleText,
	integrationsModalText,
	integrationsModalTitle,
} from '../constants'

export const IntegrationsContent = React.memo(({ bots, isModal }) => (
	<div className="integrations-content">
		{isModal ? (
			<>
				<h3>{integrationsModalTitle}</h3>
				<PanelSubtitle style={{ marginBottom: '20px' }}>{integrationsModalText}</PanelSubtitle>
			</>
		) : (
			<>
				<PanelTitle text="Интеграции" />
				<PanelSubtitle style={{ marginBottom: '12px' }}>{integrationsSubtitleText}</PanelSubtitle>
			</>
		)}
		<IntegrationsItems bots={bots} />
	</div>
))

import profile from '../../assets/media/svg/user.svg'
import avatar1 from '../../assets/media/svg/smile-plugs/avatar1.svg'
import avatar2 from '../../assets/media/svg/smile-plugs/avatar2.svg'
import avatar3 from '../../assets/media/svg/smile-plugs/avatar3.svg'
import avatar4 from '../../assets/media/svg/smile-plugs/avatar4.svg'
import avatar5 from '../../assets/media/svg/smile-plugs/avatar5.svg'
import avatar6 from '../../assets/media/svg/smile-plugs/avatar6.svg'
import avatar7 from '../../assets/media/svg/smile-plugs/avatar7.svg'
import avatar8 from '../../assets/media/svg/smile-plugs/avatar8.svg'
import avatar9 from '../../assets/media/svg/smile-plugs/avatar9.svg'
import avatar10 from '../../assets/media/svg/smile-plugs/avatar10.svg'

export const avatarPlug = (id) => {
	const switchObj = {
		0: avatar1,
		1: avatar10,
		2: avatar2,
		3: avatar3,
		4: avatar4,
		5: avatar5,
		6: avatar6,
		7: avatar7,
		8: avatar8,
		9: avatar9,
	}

	if (!!switchObj[id]) return switchObj[id]

	return profile
}

const createImage = (url) =>
	new Promise((resolve, reject) => {
		const image = new Image()
		image.addEventListener('load', () => resolve(image))
		image.addEventListener('error', (error) => reject(error))
		image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
		image.src = url
	})

function getRadianAngle(degreeValue) {
	return (degreeValue * Math.PI) / 180
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */

export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
	const image = await createImage(imageSrc)
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')

	const maxSize = Math.max(image.width, image.height)
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

	// set each dimensions to double largest dimension to allow for a safe area for the
	// image to rotate in without being clipped by canvas context
	canvas.width = safeArea
	canvas.height = safeArea

	// translate canvas context to a central location on image to allow rotating around the center.
	ctx.translate(safeArea / 2, safeArea / 2)
	ctx.rotate(getRadianAngle(rotation))
	ctx.translate(-safeArea / 2, -safeArea / 2)

	// draw rotated image and store data.
	ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
	const data = ctx.getImageData(0, 0, safeArea, safeArea)

	// set canvas width to final desired crop size - this will clear existing context
	canvas.width = pixelCrop.width
	canvas.height = pixelCrop.height

	// paste generated rotate image with correct offsets for x,y crop values.
	ctx.putImageData(
		data,
		Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
		Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
	)

	// As Base64 string
	return canvas.toDataURL('image/jpeg')

	// As a blob
	// return new Promise((resolve) => {
	// 	canvas.toBlob((file) => {
	// 		resolve(URL.createObjectURL(file))
	// 	}, 'image/jpeg')
	// })
}

import React from 'react'
import DOMPurify from 'dompurify'

export const PurifyHtml = React.memo(({ content }) => {
	const sanitizeText = DOMPurify.sanitize(content)

	return (
		<div className="purify-text">
			<pre dangerouslySetInnerHTML={{ __html: sanitizeText }} />
		</div>
	)
})

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NavItem } from './NavItem/NavItem'

import mainIcon from '../../../../assets/media/svg/nav-icons/main.svg'
import newsIcon from '../../../../assets/media/svg/nav-icons/news.svg'
import eventsIcon from '../../../../assets/media/svg/nav-icons/events.svg'
import marketIcon from '../../../../assets/media/svg/nav-icons/market.svg'
import officesIcon from '../../../../assets/media/svg/nav-icons/offices.svg'
import knowledgeBaseIcon from '../../../../assets/media/svg/nav-icons/knowledge-base.svg'
import partnersIcon from '../../../../assets/media/svg/nav-icons/partners.svg'

import {
	getIsApproved,
	getIsEmpty,
} from '../../../../store/reducers/profile/profile-selectors'

import { addNot } from '../../../../store/reducers/global-nots/global-nots'

import './Nav.scss'

export const Nav = React.memo(() => {
	const dispatch = useDispatch()

	const isEmpty = useSelector(getIsEmpty)
	const isApproved = useSelector(getIsApproved)

	const handleClick = () => {
		if (isEmpty || !isApproved) {
			dispatch(
				addNot({
					not: 'Данная страница станет доступна после одобрения анкеты',
					type: 'info',
				})
			)
		}
	}

	return (
		<nav className="nav" onClick={handleClick}>
			<NavItem exact index={1} icon={mainIcon} text="Главная" link="/" />
			<NavItem index={2} icon={newsIcon} text="Новости" link="/news" />
			<NavItem index={3} icon={eventsIcon} text="События" link="/events" />
			<NavItem index={4} icon={marketIcon} text="Маркет" link="/market" />
			<NavItem index={5} icon={officesIcon} text="Офисы" link="/offices" />
			<NavItem
				index={6}
				icon={knowledgeBaseIcon}
				text="База знаний"
				link="/knowledge-base"
			/>
			<NavItem
				index={7}
				icon={partnersIcon}
				text="Партнерство"
				link="/partners"
			/>
		</nav>
	)
})

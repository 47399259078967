import React from 'react'
import { FilterItem } from '../Filters/Filter/FilterItem/FilterItem'

import './MobileFilters.scss'

export const MobileFilters = React.memo(({ filters, reset, setFilterID }) => (
	<div className="mobile__filter">
		<div className="filters-header">
			<div className="filters-header__title">Фильтры</div>
			<div className="filters-header__reset" onClick={reset}>
				<i></i>
				<span>Сбросить</span>
			</div>

			{filters.map((item, index) => (
				<FilterItem
					key={`${item.title}-${index}`}
					{...item}
					filter={item}
					handler={setFilterID(index)}
				/>
			))}
		</div>
	</div>
))

import React, { useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import { AcceptCertificatePage } from './pages/AcceptCertificatePage/AcceptCertificatePage'
import { AppLayout } from './components/AppLayout/AppLayout'
import { BotIntegrations } from './components/BotsIntegrations/BotsIntegrations'
import EventsPage from './pages/EventsPage/EventsPage'
import { GlobalNots } from './components/GlobalNots/GlobalNots'
import { integrationsExceptions } from './integartions-exceptions'
import { Landing } from './pages/Landing/Landing'
import { PagesRouter } from './components/PagesRouter/PagesRouter'
import { RegistPage } from './pages/RegistPage/RegistPage'

import {
	requestPersDataConsent,
	requestPrivacyPolit,
	setDevice,
	connectWs,
	initialize,
	requestValidCertificates,
	requesSkillsList,
	sendRefsConversion,
} from './store/reducers/app/app'
import { requestBots } from './store/reducers/profile/profile'

import { getInitialized, getIsLoading } from './store/reducers/app/app-selectors'
import {
	getBots,
	getIsBotsLoading,
	getIsUserHaveBot,
	getId,
	getSurname,
	getName,
	getEmail,
	getIsModerating,
	getIsApproved,
} from './store/reducers/profile/profile-selectors'
import { getIsAuth } from './store/reducers/auth/auth-selectors'

import { getFromFlag, getRefFlag, setFromFlag, setRefFlag } from './functions/localStorage'

import './assets/styles/index.scss'

export const App = () => {
	const dispatch = useDispatch()

	const location = useLocation()

	const [isBotsModal, setIsBotsModal] = useState(false)

	const initialized = useSelector(getInitialized)

	const isLoading = useSelector(getIsLoading)
	const isAuth = useSelector(getIsAuth)

	const isModerating = useSelector(getIsModerating)
	const isApproved = useSelector(getIsApproved)

	const bots = useSelector(getBots)
	const isBotsLoading = useSelector(getIsBotsLoading)
	const isUserHaveBot = useSelector(getIsUserHaveBot)

	const userId = useSelector(getId)
	const name = useSelector(getName)
	const surname = useSelector(getSurname)
	const mail = useSelector(getEmail)

	// данные для smartsupp
	const userInfo = JSON.stringify({ name: name, surname: surname, mail: mail })
	if (name && surname && mail) localStorage.setItem('info', userInfo)

	const [size, setSize] = useState(window.innerWidth)

	useEffect(() => {
		dispatch(initialize())
	}, [dispatch])

	useEffect(() => {
		if (initialized) {
			dispatch(requestPrivacyPolit())
			dispatch(requestPersDataConsent())
			dispatch(requesSkillsList())
		}

		if (initialized && isAuth) {
			dispatch(connectWs())
			dispatch(requestValidCertificates())
		}
	}, [dispatch, initialized, isAuth])

	/*
		Аналитика переходов и запись откуда пришел человек
	*/
	useEffect(() => {
		if (initialized) {
			const linkParams = location.search
			const referrer = document.referrer

			const refFlag = getRefFlag()
			const fromFlag = getFromFlag()

			if (!!linkParams.length && (!refFlag || !fromFlag)) {
				const data = new FormData()
				data.append('ref', linkParams)
				data.append('from', referrer)
				setRefFlag(linkParams)
				setFromFlag(referrer)
				dispatch(sendRefsConversion(data))
			}

			return
		}
	}, [dispatch, initialized, location])

	const updateWidth = () => {
		setSize(window.innerWidth)
	}

	useEffect(() => {
		window.addEventListener('resize', updateWidth)

		const setDeviceValue = (screenValue) => {
			if (screenValue >= 1200) {
				dispatch(setDevice({ device: 'desktop' }))
			} else if (screenValue < 1200 && screenValue >= 1024) {
				dispatch(setDevice({ device: 'small-desktop' }))
			} else if (screenValue < 1024 && screenValue >= 768) {
				dispatch(setDevice({ device: 'notepad' }))
			} else {
				dispatch(setDevice({ device: 'mobile' }))
			}
		}

		setDeviceValue(size)

		return () => window.removeEventListener('resize', updateWidth)
	}, [dispatch, size])

	useEffect(
		() => () => {
			window.removeEventListener('resize', updateWidth)
		},
		[]
	)

	useEffect(() => {
		if (
			isAuth &&
			!isUserHaveBot &&
			(isModerating || isApproved) &&
			!integrationsExceptions.includes(userId)
		) {
			dispatch(requestBots(isUserHaveBot))
			setIsBotsModal(true)
		} else {
			setIsBotsModal(false)
		}
	}, [dispatch, isModerating, isApproved, isAuth, isUserHaveBot, userId])

	if (!initialized || isLoading) return null

	return (
		<>
			{isModerating && (
				<Helmet>
					<script type="text/javascript">
						{`!function(){
							var t=document.createElement("script");
							t.type="text/javascript",
							t.async=!0,
							t.src="https://vk.com/js/api/openapi.js?169",
							t.onload=function(){
								VK.Retargeting.Init("VK-RTRG-1024680-fCZcM"),
								VK.Retargeting.Hit()
							},
							document.head.appendChild(t)
						}();`}
					</script>
					<noscript>
						{`<img
							src="https://vk.com/rtrg?p=VK-RTRG-1024680-fCZcM"
							style="position:fixed; left:-999px;"
							alt=""
						/>`}
					</noscript>
				</Helmet>
			)}

			{isUserHaveBot && (
				<Helmet>
					<script type="text/javascript">
						{`!function(){
						var t=document.createElement("script");
						t.type="text/javascript",
						t.async=!0,
						t.src="https://vk.com/js/api/openapi.js?169",
						t.onload=function(){
							VK.Retargeting.Init("VK-RTRG-1024682-hzw7q"),
							VK.Retargeting.Hit()
						},
						document.head.appendChild(t)
					}();`}
					</script>
					<noscript>
						{`<img
							src="https://vk.com/rtrg?p=VK-RTRG-1024682-hzw7q"
							style="position:fixed; left:-999px;"
							alt=""
						/>`}
					</noscript>
				</Helmet>
			)}

			{isApproved && (
				<Helmet>
					<script type="text/javascript">
						{`!function(){
						var t=document.createElement("script");
						t.type="text/javascript",
						t.async=!0,
						t.src="https://vk.com/js/api/openapi.js?169",
						t.onload=function(){
							VK.Retargeting.Init("VK-RTRG-1024683-hQphO"),
							VK.Retargeting.Hit()
							},
						document.head.appendChild(t)
					}();`}
					</script>
					<noscript>
						{`<img
							src="https://vk.com/rtrg?p=VK-RTRG-1024683-hQphO"
							style="position:fixed; left:-999px;"
							alt=""
						/>`}
					</noscript>
				</Helmet>
			)}

			<Helmet>
				<meta name="title" content=" Главная страница" />
				<meta name="description" content="Тест" />
			</Helmet>

			{!!~location.pathname.search('certificate') && !~location.pathname.search('certificates') ? (
				<Route path="/certificate/:hash?" render={() => <AcceptCertificatePage />} />
			) : isAuth ? (
				<>
					<AppLayout>
						<PagesRouter />
					</AppLayout>
					<BotIntegrations bots={bots} showModal={!isBotsLoading && isBotsModal} isModal />
				</>
			) : (
				<Switch>
					<Route path="/login" render={() => <RegistPage />} />

					<Route path="/checkin" render={() => <RegistPage checkin />} />

					<Route path="/signup" render={() => <RegistPage checkin firstText />} />

					<Route path="/registration" render={() => <RegistPage checkin secondText />} />

					<Route
						exact
						path="/events/:id"
						render={() => (
							<AppLayout>
								<EventsPage />
							</AppLayout>
						)}
					/>

					<Route path="*" render={() => <Landing />} />
				</Switch>
			)}
			<GlobalNots />
		</>
	)
}

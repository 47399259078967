import React from 'react'
import { TransformCard } from './TransformCard/TransformCard'

import './ProfitFourth.scss'

export const ProfitFourth = React.memo(() => {
	return (
		<div className="profit-fourth">
			<div className="profit-fourth__title title">
				Специальные программы
				<br />
				от x-cluster
			</div>
			<div className="profit-fourth__flip-cards">
				<TransformCard type="work">
					<div className="profit-fourth__flip-cards-title subtitle">
						Программа по трудоустройству
					</div>
					<div className="profit-fourth__flip-cards-text">
						Если ты находишься в поиске работы, мы поможем подобрать вакансию или контракт в
						соответствии с твоими навыками и предпочтениями.
						<br /> В поиске работы мы помогаем как продвинутым специалистам, так и новичкам.
					</div>
				</TransformCard>
				<TransformCard type="campus">
					<div className="profit-fourth__flip-cards-title subtitle">Программа по стажировке</div>
					<div className="profit-fourth__flip-cards-text">
						Ты можешь получить практику в реальных IT-компаниях.
						<br /> Мы предлагаем участникам сообщества различные варианты стажировок и помогаем
						адаптироваться на новом месте.
					</div>
				</TransformCard>
			</div>
		</div>
	)
})

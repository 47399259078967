import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	cities: {},
	countries: {},
	language: {},
	specs: {},
	techs: {},
}

const local = createSlice({
	name: 'local',
	initialState,
	reducers: {
		setAll: (state, action) => {
			state.cities = action.payload.local.cities
			state.countries = action.payload.local.countries
			state.language = action.payload.local.language
			state.specs = action.payload.local.specs
			state.techs = action.payload.local.techs
		},
	},
})

const { reducer, actions } = local

export const { setAll } = actions

export default reducer

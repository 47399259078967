import React from 'react'
import { Icon } from '../Icon/Icon'

import { generateID } from '../../functions/common'

import './Checkbox.scss'

export const Checkbox = ({ name, disabled, checked, onChange }) => {
	const id = generateID('cb-')
	return (
		<label htmlFor={id} className="checkbox-main">
			<input
				name={name}
				id={id}
				type="checkbox"
				checked={checked}
				onChange={onChange}
				disabled={disabled}
			/>
			<span>
				<Icon name="checkmark-white" size={14} />
			</span>
		</label>
	)
}

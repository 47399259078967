import React from 'react'
import cn from 'classnames'

import { NavItem } from './NavItem/NavItem'

import './Nav.scss'

export const Nav = React.memo(({ burger, classname }) => {
	return (
		<div className={cn('nav-links', classname, { burger })}>
			<NavItem anchor="#about" label="О проекте" />
			<NavItem anchor="#profit" label="Что вы получаете?" />
			<NavItem anchor="#faq" label="FAQ" />
			<NavItem anchor="#partnership" label="Стать партнером" />
			<NavItem anchor="#contacts" label="Контакты" />
		</div>
	)
})
